.top-div {
  width: 100%;
  margin-top: 60px;
  margin-bottom: 50px;

  display: flex;
}
.bottom-div {
  width: 100%;
  margin-bottom: 60px;

  display: flex;
}

.ExtraRegistration {
  width: 90%;
  margin: auto;

  box-shadow: 1px 1px 10px 2px rgba(49, 49, 49, 0.1);
  border-radius: 20px;
}

.registration-steps {
  width: 100%;
  margin: auto;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  margin-bottom: 25px;
}

.registration-steps .step {
  width: 100%;
  min-height: 15vh;
  height: auto;
  /* margin: auto; */

  display: grid;
  grid-template-columns:repeat(5, 1fr);
  /* grid-template-rows: auto; */
  gap: 16px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  box-shadow: 1px 1px 4px 1px rgba(49, 49, 49, 0.1);
  border-radius: 15px;
  margin: 10px 0;
}

.step-element {
  /* width: 25%; */
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  /* gap:16px; */
  align-items: flex-start;
}
.step-element:first-child {
  margin-left: 3%;
}
/* .step-element:last-child {
  width: 15%;
} */

.step-element h3 {
  font-size: 18px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 500;
}

.step-element h4 {
  font-size: 16px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 500;
}
.step-element h4 span {
  font-size: 16px;
  font-weight: 400;
}

.step-element button {
  width: 80%;
  height: 36px;
  font-size: 16px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  border-radius: 10px;
  margin: auto;
}
