.otherPageCont {
  width: 100%;
  animation: fadeIn 0.5s ease;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.h_top {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 10px 20px;
  transition: all 0.5s ease;
  gap: 20px;
}

.h_title {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 5px;
  margin-left: 5px;
}

.h_top .h_leftPart {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 10px;
  width: 50%;
}

.h_top .h_rightPart {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  width: 50%;
}

.h_top .h_leftPart .h_upperHalf,
.h_top .h_leftPart .h_lowerHalf {
  width: 100%;
}

.h_top .h_rightPart .h_right,
.h_upperHalf .h_left,
.h_lowerHalf .h_left {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  border-radius: 5px;
  background: #dff2fa;
  box-shadow: 1px 1px 5px 2px rgba(32, 32, 32, 0.156);
  padding: 5px 0px;
  font-size: 18px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.h_rightPart .h_right:hover,
.h_top .h_left:hover {
  box-shadow: 2px 2px 10px 5px rgba(32, 32, 32, 0.25);
}

.h_upperHalf .h_left {
  flex-direction: row;
}

.h_bottom {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 8px 0px;
  transition: all 0.5s ease;
  box-shadow: 1px 1px 5px 2px rgba(32, 32, 32, 0.156);
  background: #dff2fa;
  width: 94%;
  margin: 10px auto;
  border-radius: 5px;
  font-size: 18px;
  transition: all 0.3s ease;
  cursor: pointer;
}

.h_bottom:hover {
  box-shadow: 2px 2px 10px 5px rgba(32, 32, 32, 0.25);
}

.h_content {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 20px;
  gap: 8px;
}

.h_content > span {
  font-weight: 800;
}

.h_update {
  /* flex-direction: column; */
}

.h_col {
  display: flex;
  flex-direction: column;
}

.h_col > span {
  font-weight: 800;
}

.h_row {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.h_bottom .h_content {
  width: fit-content;
}

.swapSwitch {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.trackPart {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  background: #dff2fa;
  padding: 3px 5px;
  border-radius: 5px;
  margin: 10px 20px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: inset 1px 1px 5px 2px rgba(32, 32, 32, 0.156);
}
