.generalFilterContainer {
  align-items: center;
  border-radius: 20px;
  box-shadow: 1px 1px 10px 2px #3131311a;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  margin: 0 auto 30px;
  padding: 30px;
  width: 90%;
  margin-top: 50px;
}

.filterHeader {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  border-bottom: 2px solid black;
  margin-bottom: 10px;
}

.filterHeader p {
  font-size: 26px;
  font-weight: 600;
  color: #313131;
  margin: 3px 0px;
}

.generalFilterResetBtn {
  background-color: transparent !important;
  border: 2px solid #000 !important;
  color: #000 !important;
  cursor: pointer;
  padding: 5px 20px;
  font-size: 16px;
  border-radius: 5px;
}
.generalFilterResetBtn:hover {
  border: 2px solid transparent !important;
  background-color: #e24825cd !important;
  color: white !important;
  transition: ease-in-out 0.2s;
}

.generalFilterResetBtn i {
  transition: ease-in-out 0.3s;
}

.generalFilterResetBtn:hover i {
  transform: rotate(360deg);
}

.generalFilterSection {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: flex-start;
  margin: auto;
  width: 96%;
}

.generalRollInput input {
  height: 24px;
  width: 150px;
  display: block;
  padding: 5px;
  border-radius: 10px;
  background-color: #f8feff;
  border: 1px solid transparent;
  box-shadow: inset 1px 1px 3px 1px rgba(60, 60, 60, 0.1);
  font-size: 16px;
  font-family: inherit;
  /* text-transform: capitalize; */
  color: rgb(225, 183, 0);
  font-weight: 400;
  transition: all 0.5s ease;
}

.generalRollInput input:focus {
  border: 2px solid #f3b300;
}

.generalRollInput input::placeholder {
  color: #f3b2006d;
}

.generalRollInput input:active {
  border: 2px solid #f3b300;
}
