.result-window {
  width: 90%;
  height: 100%;
  margin: 0 auto;

  display: flex;
  flex-direction: row;
  align-items: space-between;
}
.result-display {
  width: 100%;
  height: 100%;
  margin: 0;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.result-display-div,
.result-menu-div,
.result-side-div {
  box-shadow: 1px 1px 10px 2px rgba(49, 49, 49, 0.1);
  border-radius: 20px;
}

.result-menu-div {
  height: 100%;
  width: 40%;
  margin: 0 auto;
  padding: 20px 0;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.result-display-div {
  width: calc(80% - 20px);
  height: 100%;
}

.marksheet {
  width: calc(100% - 20px);
  height: 100%;
  margin: 10px auto;

  background-image: url("../../assets/watermark.svg");
  background-repeat: no-repeat;
  background-position: center;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;

  border: 1px solid #4e4e4e;
  border-radius: 15px;
}

.marksheet-header,
.marksheet-student-details,
.marksheet-subject-scores,
.marksheet-total-score,
.marksheet-footer {
  width: 100%;
  height: auto;
  margin: 0 auto;
}

.Institute-banner {
  width: 96%;
  height: 100%;
  margin: 0 auto;

  border-radius: 15px 15px 0 0;
}
.Institute-banner img {
  width: 100%;
  height: auto;
  margin: 0;
  object-fit: cover;
  border-radius: 15px 15px 0 0;
}

.marksheet-subheader {
  width: 70%;
  height: auto;
  margin: 10px auto;
  padding: 10px 0;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  border-top: 2px solid #4e4e4e;
  border-bottom: 2px solid #4e4e4e;
}

.marksheet-subheader h4 {
  font-size: 24px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 500;
  margin: 0;
}
.marksheet-subheader h4 span {
  font-size: 18px;
  font-weight: 400;
  color: #4e4e4e;
}

.marksheet-student-details {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;

  margin: 20px 0;
}

.student-details-top {
  width: 90%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}
.student-details-bottom {
  width: 90%;
  height: auto;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.marksheet-student-details h4 {
  font-size: 18px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 400;
  margin: 10px 0;
}
.marksheet-student-details h4 span {
  font-size: 18px;
  font-weight: 500;
  color: #252525;
}
.student-details-left {
  width: 50%;
  height: auto;
  margin: 0;
  padding: 0;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
}
.student-details-right {
  width: 50%;
  height: auto;
  margin: 0;
  padding: 0;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-end;
}

.marksheet-subject-scores {
  width: 90%;
  height: auto;
  margin: 20px auto;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;

  border: 2px solid #4e4e4e;
  border-radius: 10px;
}

.subject-scores-header,
.subject-scores-body {
  width: 100%;
  height: auto;
  margin: 0;
  padding: 0;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  border-bottom: 2px solid #4e4e4e;
}

.subject-scores-header {
  background-color: #d8e7f5;
  height: 36px;
  border-radius: 8px 8px 0 0;
}
.subject-scores-contents {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.subject-scores-body {
  height: 32px;
}

.subject-scores-body:last-child {
  border-bottom: transparent;
  border-radius: 0 0 10px 10px;
}

.subject-scores-header-element,
.subject-scores-body-element {
  width: 10%;
  height: 100%;
  margin: 0;
  padding: 0;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;

  border-right: 2px solid #4e4e4e;
}

.subject-scores-header-element:first-child,
.subject-scores-body-element:first-child {
  align-items: flex-start;
}

.subject-scores-header-element:last-child,
.subject-scores-body-element:last-child {
  align-items: flex-end;
  border-right: none;
}

.subject-scores-header-element:nth-child(2),
.subject-scores-body-element:nth-child(2) {
  width: 45%;
  align-items: flex-start;
}
.subject-scores-header-element:nth-child(5),
.subject-scores-body-element:nth-child(5) {
  width: 15%;
}

.marksheet-subject-scores h4 {
  font-size: 16px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 500;
  margin: 5px 8px;
}

.subject-scores-header h4 {
  font-size: 18px;
}
.subject-scores-body h4 {
  color: #252525;
}

.marksheet-total-scores,
.marksheet-footer {
  width: 90%;
  height: auto;
  margin: 10px auto;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  border-radius: 10px;
}

.total-scores-top,
.total-scores-bottom {
  width: 100%;
  height: auto;
  margin: 0;
  padding: 0;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.total-scores-bottom {
  border: 2px solid #4e4e4e;
  border-radius: 10px;
  background-color: #d8e7f5;
  margin: 20px 0;
}

.total-scores-left {
  width: 50%;
  height: auto;
  margin: 0;
  padding: 0;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}
.total-scores-right {
  width: 50%;
  height: auto;
  margin: 0;
  padding: 0;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}

.marksheet-total-scores h4 {
  font-size: 18px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 400;
  margin: 8px 10px;
}
.marksheet-total-scores h4 span {
  font-weight: 500;
  color: #252525;
}

.total-scores-left p {
  font-size: 14px;
  font-weight: 400;
  color: #252525;
}
.total-scores-right img {
  width: 120px;
  height: auto;
  margin: 0;
  object-fit: cover;
}

.result-side-div {
  width: calc(100% - 40px);
  height: 100%;
  margin: 0;
  margin-left: 40px;
  margin-bottom: 40px;
  padding: 20px 0;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.result-element {
  width: 90%;
  height: 60px;
  margin: 5px auto;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  background-color: #d8e7f5;
  border: 1px solid transparent;
  cursor: pointer;

  border-radius: 10px;
}

.result-element:hover,
.active-element {
  border: 1px solid #fff;
  background-color: #5a6dff;
  color: #fff;
  box-shadow: 1px 1px 4px 1px rgba(49, 49, 49, 0.1);
  transition: all 0.2s ease-in-out;
}

.result-element h4 {
  font-size: 16px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  margin: 0 10px;
  font-weight: 500;
}
.result-element h4 span {
  font-weight: 400;
}

/* mobile view */

@media screen and (max-width: 768px) {
  .top-div {
    margin-top: 40px;
  }
  .mid-div {
    margin: 40px 0;
  }
  .bottom-div {
    margin-bottom: 40px;
  }
  .result-display {
    flex-direction: column-reverse;
    justify-content: flex-start;
  }

  .result-display-div {
    width: 200%;
    transform: scale(0.5);
    transform-origin: top left;
    padding: 0;
    margin: 0 auto;
  }

  .result-menu-div {
    width: 96%;
    height: auto;
  }

  .result-menu-div .result-element {
    margin: 5px auto;
  }

  .side-div {
    width: 100%;
    margin: 0 auto;
  }
  .result-side-div {
    width: 100%;
    height: auto;
    margin: 0;
    padding: 15px 0;

    flex-direction: row;
    justify-content: flex-start;
    overflow: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;

    margin-bottom: 40px;
  }

  .result-side-div:last-child {
    display: none;
  }

  .result-element {
    min-width: 120px;
    height: 60px;

    margin: 0 5px;
  }
  .result-element:first-child {
    margin-left: 15px;
  }
  .result-element:last-child {
    margin-right: 15px;
  }
}
