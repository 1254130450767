.paymentTableCont {
  width: 92%;
  box-shadow: 0 0 15px #12121270;
  border-radius: 8px;
  margin: 10px 0px;
}

.RefundMark {
  background-color: #adddff;
}
