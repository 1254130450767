.ReviewHeading{
  text-align: center;
  text-decoration: underline;
  font-size: 36px;
}

.filterContainer7{
  width: 80%;
  height: 100%;
  margin: 0 auto;
  box-shadow: 1px 1px 10px 2px rgba(49, 49, 49, 0.1);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 30px;
  margin-bottom: 30px;
}

.filterSection7{
  width: 96%;
    display: flex;
    gap: 20px;
    margin: auto auto;
    align-items: center;
    justify-content: space-around;
}

.reviewContainer{
  width: 80%;
  height: 100%;
  margin: 0 auto;
  box-shadow: 1px 1px 10px 2px rgba(49, 49, 49, 0.1);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  /* justify-content: space-between; */
  align-items: flex-start;
  padding: 30px;
  margin-bottom: 50px;
}

.countContainer{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 80px;
}

.CountCard{
  position: relative;
  width: 300px;
  height: 200px;
  background-color: #ecfafd;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 1px 1px 10px 2px rgba(49, 49, 49, 0.1);
}

.count{
  font-size: 42px;
  font-weight: 500;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.countText{
  position: absolute;
  bottom: 10px;
  font-size: 18px;
  font-weight: 500;
}

.viewLogs{
  width: 96%;
  height: 100%;
  margin: 0 auto;
  box-shadow: inset 1px 1px 10px 2px rgba(49, 49, 49, 0.1);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 8px 20px;
  margin-bottom: 30px;
  margin-top: 20px;
}

.viewLogHeading{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: larger;
  font-weight: 500;
}

.bottomSec{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.uploadButton{
  padding: 8px 40px;
  font-size: 18px;
  font-weight: 500;
  border-radius: 8px;
}

.SummaryCont{
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.data{
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  width: 40%;
  justify-content: center;
  padding: 20px;
  box-shadow: 1px 1px 10px 2px rgba(49, 49, 49, 0.1);
  border-radius: 20px;
}

.section{
  width: 100%;
  font-size: 24px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.facultyCont{
  display: flex;
  width: 90%;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  /* padding: 10px 50px; */
  margin: 50px auto;
  gap: 30px;
}

.tableCont{
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
  border-radius: 5px;
  box-shadow: 1px 1px 10px 2px rgba(49, 49, 49, 0.1);
}

.filterCont{
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 5px;
  border-radius: 10px;
  box-shadow: 1px 1px 10px 2px rgba(49, 49, 49, 0.1);
  max-height: 600px;
  height: 400px;
  position: sticky;
}

.facultyFeedback2{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 5px;
  border-radius: 5px;
  box-shadow: 1px 1px 10px 2px rgba(49, 49, 49, 0.1);
}

.topHeader{
  display: flex;
  background: #f3b300;
  color: white;
  font-size: 16px;
  font-weight: 500;
  width: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.makeItBold{
  font-weight: 600;
}

.header{
  display: flex;
  font-size: 16px;
  width: 100%;
  cursor: pointer;
}

.facultyName{
  width: 50%;
  padding: 10px;
}

.overallRating{
  width: 25%;
  padding: 10px;
  text-align: center;
}

.position{
  width: 25%;
  padding: 10px;
  text-align: center;
}

.coursesSec{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.coursesSecTable{
  width: 96%;
  padding: 3px;
  margin: auto;
  margin-bottom: 10px;
  border-collapse: separate;
  box-shadow: inset 1px 1px 10px 2px rgba(49, 49, 49, 0.1);
}

.coursesSecHead{
  background-color: #f3b300;
  color: white;
}

.coursesSecBody>tr{
  background-color: #e7f9fd;
}

.veiwBtn{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
  margin-bottom: 50px;
}

.assignCont{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-top: 100px;
}

.assignBtn{
  padding: 8px 40px;
  font-size: 18px;
  font-weight: 500;
  border-radius: 8px;
}

.remainingListDiv{
  width: 82%;
  height: 100%;
  margin: 0 auto;
  box-shadow: 1px 1px 10px 2px rgba(49, 49, 49, 0.1);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 8px 20px;
  margin-bottom: 30px;
  margin-top: 20px;
}

.listTable{
  width: fit-content !important;
  border: 1px solid #f3b300;
}

.downloadBTN{
  margin: 20px auto;
}