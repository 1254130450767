.pageContainer{
  width: 70%;
  margin: 50px auto;
  padding: 20px;
  /* background-color: #add8e67d; */
  background: url(../../../public/assets/passbook-background-svg.svg) no-repeat center center/cover; 
  border-radius: 8px;
  font-family: 'CustomFont', sans-serif;
  margin-bottom: 30px;
}

.passbook_heading{
  font-size: 1.8rem;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
  text-decoration: underline;
}

.detailContainer_bank{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  color: #5f5f5f;
}

.leftPart_bank>p{
  display: flex;
  width: 35vw;
  font-weight: lighter;
}

.rightPart_bank{
  width: 45vw;
  height: 100%;
}

.detailHeading{
  display: block;
  width: 155px;
}

.uploadSection_bank{
  /* width: 100%; */
  text-align: center;
  border-radius: 12px;
  border: 1px solid #5f5f5f;
  height: 200px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #f8feff;
  gap: 5px;
  align-items: center;
  padding: 20px;
}

.bank-input{
  border: 2px solid transparent;
  background-color: #f8feff;
  box-shadow: inset 1px 1px 3px 1px rgba(60, 60, 60, 0.1);
  padding: 5px 10px;
  border-radius: 10px;
  color: #5f5f5f;
  cursor: pointer;
  font-size: 15px;
  font-family: inherit;
}

.uploadImg{
  width: 40px;
  height: 40px;
}

.btnCont{
  display: flex;
  justify-content: center;
  margin-top: 10px;
  align-items: center;
  gap: 20px;
}

.bankBtn{
  padding: 10px 40px;
  font-size: 16px;
  border-radius: 10px;
}