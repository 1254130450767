.feedback-page-div {
  width: 90%;
  margin: 0 auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  box-shadow: 1px 1px 10px 2px rgba(49, 49, 49, 0.1);
  border-radius: 20px;
}

.FeedbackPage {
  width: 90%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.FeedbackPage h2 {
  font-size: 24px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  color: #4e4e4e;
  font-weight: 400;
  margin-bottom: 30px;
  text-align: center;
}

.FeedbackPage form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  max-width: 600px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;

  margin-bottom: 50px;
}

.FeedbackPage .form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  width: 100%;
}

.FeedbackPage label {
  font-size: 18px;
  margin-bottom: 10px;
}

/* .FeedbackPage input,
.FeedbackPage select,
.FeedbackPage textarea {
  font-size: 16px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 96%;
  margin-top: 5px;
} */

.FeedbackPage textarea {
  height: 150px;
  border-radius: 8px;
  width: 90%;
  padding: 10px;
  border: 1px solid #ccc;
  resize: none;
}

.FeedbackPage button[type="submit"] {
  background-color: #007bff;
  color: #fff;
  font-size: 18px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  margin-top: 20px;
  cursor: pointer;
}

.FeedbackPage .success-message {
  background-color: #d4edda;
  color: #155724;
  font-size: 18px;
  padding: 10px;
  border: 1px solid #c3e6cb;
  border-radius: 5px;
  margin-top: 20px;
  text-align: center;
  margin-bottom: 30px;
}

.positionCont{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: white;
  border-radius: 5px;
  padding: 10px;
  width: 90%;
  border: 1px solid #ccc;
}