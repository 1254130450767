.top-div {
  width: 100%;
  margin-top: 60px;
  margin-bottom: 60px;

  display: flex;
}

.bottom-div {
  width: 100%;
  margin-bottom: 60px;
}
