.paymentTableCont7 {
  width: 93%;
  height: 100%;
  /* box-shadow: 0 0 5px #12121270;
  border-radius: 8px; */
  margin: 0px auto;
  /* padding: 10px; */
}

.paymentTable7 {
  border-collapse: initial;
  border-radius: 10px;
  display: block;
  overflow-y: auto;
  position: relative;
  margin: 10px auto;
  width: fit-content;
  box-shadow: 0 0 10px #12121270;
  padding: 5px;
  height: 100%;
  max-height: 70vh;
}

.paymentTableHead {
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 20;
}

.paymentTableHead th {
  background: #f3b300 !important;
  text-align: center;
  color: white;
  padding: 8px 20px;
  text-align: center;
  border-bottom: 1px solid #e8e8e8;
  border-right: 1px solid #e8e8e8;
}

.paymentTableBody td {
  padding: 8px 20px;
  text-align: center;
  border-bottom: 1px solid #e8e8e8;
  border-right: 1px solid #e8e8e8;
}

.payableRows {
  background: rgba(206, 255, 133, 0.6);
}

.paidRows {
  background: rgba(108, 254, 108, 0.6);
}
