.bonafide-assign {
  width: 90%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.bonafide-assign .search-student {
  width: calc(80% - 25px);
  margin: 0;
}

.search-div-application{
  width: 50%;
  padding: 0px 20px;
}

.bonafide-requests {
  width: calc(20% - 25px);
  box-shadow: 1px 1px 10px 2px rgba(49, 49, 49, 0.1);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.bonafide-requests .title {
  width: 90%;
  margin: 0 auto;
  border-bottom: 2px solid #4e4e4e;
}

.bonafide-requests .requests-view {
  width: 90%;
  margin: 5% auto;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;

  border-radius: 10px;
  background: #f8feff;
}

.requests-view h3 {
  font-size: 16px;
  font-weight: 500;
  color: #4e4e4e;
  margin: 0;
}
.requests-view h3 span {
  font-size: 24px;
  font-weight: 600;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  color: #4e4e4e;
  margin: 0;
}

.inner-title{
  width: 100% !important;
}

.requests-view:hover {
  cursor: pointer;
  background: #5a6dff;
}
.requests-view:hover h3,
.requests-view:hover h3 span {
  color: #fff;
}

.student-bonafide-result {
  width: 90%;
  margin: 0 auto;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.bonafide-generated,
.bonafide-actions {
  box-shadow: 1px 1px 10px 2px rgba(49, 49, 49, 0.1);
  border-radius: 20px;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.bonafide-generated {
  width: calc(60% - 25px);
  max-width: 790px;
  overflow: hidden;
  background-color: #fff;
  font-family: 'Times New Roman', Times, serif !important;
}

.bonafide-generated-head {
  display: flex;
  justify-content: center;
  font-family: 'Times New Roman', Times, serif !important;
}
.bonafide-generated-head img {
  width: 100%;
  padding: 0.5%;
}
.horz-line {
  border-bottom: 1px solid black;
}
.bonafide-generated-contacts {
  display: flex;
  justify-content: space-between;
  padding: 0 5%;
  font-family: 'Times New Roman', Times, serif !important;
}
.bonafide-generated-contacts h4 {
  font-size: 16px;
  font-weight: 500;
  color: #4e4e4e;
  font-family: 'Times New Roman', Times, serif !important;
  margin: 2px 0;
}

.reference-id {
  display: flex;
  justify-content: space-between;
  padding: 0.25% 5%;
  margin-top: 10px;

  font-family: 'Times New Roman', Times, serif !important;
  color: #252525;
}
.bonafide-generated-heading {
  display: flex;
  justify-content: center;
  padding: 0.25% 5%;
  color: #252525;
}
.bonafide-generated-heading h3 {
  font-size: 24px;
  font-weight: 600;
  margin: 20px;
  font-family: "Montserrat", sans-serif;
}
.pic {
  display: flex;
  justify-content: flex-end;
  padding: 0.25% 5%;
}
.pic img {
  width: 20%;
  border: 1px solid #4e4e4e;
  border-radius: 4px;
  margin-bottom: 20px;
}
.body-1,
.body-2,
.body-3,
.body-4 {
  padding: 5px 5%;
}
.body-4 {
  margin-top: 90px;
}
.body-1 p,
.body-2 p,
.body-3 p {
  /* font-family: sans-serif; */
  text-align: justify;
  line-height: 30px;
  margin: 0;
}
.body-3 p .underline {
  min-width: 30px;
  border-bottom: 1px solid black;
  font-weight: bold;
}
.body-4 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  text-align: right;
  padding: 0.5% 5%;
}

.bonafide-actions {
  width: calc(40% - 25px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  height: fit-content;
}

.bonafide-actions .action {
  width: 92%;
  margin: 0 auto;
  height: calc(50% - 5px);

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 30px auto;
}

.action form {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
}

.action input {
  margin-bottom: 15px;
}

.action button {
  width: 100%;
  height: 40px;

  font-size: 18px;
  font-weight: 500;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  border-radius: 10px;
  margin-top: 15px;
}

.bonafide-action-btn {
  width: 100%;
  margin: 0;
}

.action .new-btn {
  width: 100%;
  height: 100%;
  border-radius: 10px;

  color: white;
  background-color: #5a6dff;
  border: none;
  font-size: 18px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  cursor: pointer;
}

.new-btn:hover {
  background-color: #f3b300;
}

.result-content-item-application{
  text-align: center;
  
}

.content-application{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
}

.second-part{
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  gap: 10px;
  padding: 0px 20px;
}

.option-list{
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
}

.application-title{
  font-weight: bold;
  font-size: 18px;
}

.bonafide-assign-history {
  width: 90%;
  margin: auto;

  box-shadow: 1px 1px 10px 2px rgba(49, 49, 49, 0.1);
  border-radius: 20px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.bonafide-history-contents {
  width: 96%;
  height: 800px;
  margin: 5px auto;
}

.re-generate a {
  font-size: 18px;
  font-weight: 500;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  color: #4e4e4e;
  text-decoration: none;
  margin: 0;
  cursor: pointer;
  color: #5a6dff;
}
