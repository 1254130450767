.GraphsContiner {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 50px;
  margin-bottom: 50px;
}

.pieAndLineContainer {
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
}

.barContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
}

.bartitle,
.lineTitle,
.pietitle {
  width: 100%;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
}
