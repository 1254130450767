.summary {
  width: 90%;
  margin: auto;

  box-shadow: 1px 1px 10px 2px rgba(49, 49, 49, 0.1);
  border-radius: 20px;
}

.summary-div {
  width: 96%;
  margin: auto;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.summary-left-div {
  text-align: left;
}
.summary-right-div {
  text-align: right;
}

.summary-div h3 {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 600;
  font-size: 18px;
}
.summary-div h3 span {
  font-weight: 500;
}
.NoteOnPaymentDetails {
  font-size: 12px;
  color: #8c8c8c;
  margin: 10px 2%;
}

/* ----- Responsive ----- */

@media (max-width: 480px) {
  .summary-div h3 {
    font-size: 16px;
  }
}
