.data-upload-div {
  width: 90%;
  margin: auto;
  box-shadow: 1px 1px 10px 2px rgba(49, 49, 49, 0.1);
  border-radius: 20px;
}
.data-upload-contents {
  width: 96%;
  height: 100%;
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.data-upload-details {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
}

.data-upload-details h2 {
  margin-top: 30px;
  margin-bottom: 10px;
  font-size: 24px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 500;
  color: #333;
}

.data-upload-details p {
  margin: 0;
  font-size: 16px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 400;
  color: #333;
  margin-bottom: 30px;
}

.data-upload-details a {
  text-decoration: none;
  color: #5a6dff;
  font-size: 16px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 500;
}

.data-upload-details2 {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
}

.data-upload-details2 span {
  margin: 0;
  font-size: 20px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 400;
  color: #333;
  flex: 1;
}

.inner-element{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 30px;
  margin-bottom: 30px;
}

.data-upload-buttons {
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.data-upload-buttons input {
  width: 50%;
}
.data-upload-buttons button {
  width: 30%;
  height: 40px;
  border-radius: 10px;
  border: none;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
}

.data-upload-buttons2 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.data-upload-buttons2 button {
  width: 100%;
  height: 40px;
  border-radius: 10px;
  border: none;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  padding: 0px 30px;
}

.data-upload-divs {
  width: 90%;
  height: 100%;
  margin: auto;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.data-div-left,
.data-div-right {
  width: calc(50% - 20px);
  height: 100%;

  box-shadow: 1px 1px 10px 2px rgba(49, 49, 49, 0.1);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.data-div-left-contents,
.data-div-right-contents {
  width: 92%;
  height: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
}

.data-div-left-details,
.data-div-left-buttons,
.data-div-right-details,
.data-div-right-buttons {
  width: 100%;
}

.data-div-left-buttons,
.data-div-right-buttons {
  margin-bottom: 30px;
}

.Individual-data-update-div {
  width: 90%;
  margin: auto;
  box-shadow: 1px 1px 10px 2px rgba(49, 49, 49, 0.1);
  border-radius: 20px;
}

.Individual-data-update-contents {
  width: 96%;
  height: 100%;
  margin: auto;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  border-bottom: 2px solid #4e4e4e;
}

.Individual-data-update-details {
  width: calc(50% - 20px);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
}

.Individual-data-update-details h2 {
  margin-top: 30px;
  margin-bottom: 10px;
  font-size: 24px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 500;
  color: #333;
}

.Individual-data-update-details p {
  margin: 0;
  font-size: 16px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 400;
  color: #333;
  margin-bottom: 30px;
}

.Individual-search {
  width: calc(50% - 20px);
  height: 100%;

  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.Individual-search input {
  width: 40%;
  height: 36px;
  border: none;
  border-radius: 10px;
  background-color: #f8feff;
  border: 1px solid transparent;
  box-shadow: inset 1px 1px 3px 1px rgba(60, 60, 60, 0.1);
  font-size: 16px;
  font-weight: 500;
  font-family: sans-serif;
  padding-left: 20px;

  margin-right: 30px;
}

.Individual-search input:focus {
  outline: none;
  border: 1px solid #f3b300;
}

.Individual-search button {
  width: 30%;
  height: 36px;
  border: none;
  border-radius: 10px;
  background-color: #f3b300;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  font-family: sans-serif;
  cursor: pointer;
}

.Individual-search button:hover {
  background-color: #5a6dff;
  opacity: 0.8;
}

.Individual-search-results {
  width: 96%;
  margin: auto;
  border-bottom: 2px solid #4e4e4e;
}
.borderTop {
  border-top: 2px solid #4e4e4e;
}

.Individual-search-results-contents {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #f8feff;
}

.Individual-search-results-contents h4 {
  width: calc(25% - 10px);
  font-size: 18px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 400;
  font-family: sans-serif;
  color: #333;

  margin: 20px 5px;
  text-align: left;
}
.Individual-search-results-contents h4 span {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  color: #5a6dff;
  font-weight: 600;
}

.Individual-search-results-contents h4:last-child {
  text-align: right;
}

.Individual-data-update-buttons {
  width: 96%;
  margin: auto;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  margin-top: 20px;
  margin-bottom: 30px;
}

.add-other-data {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 20px;
}

.add-other-data-element {
  width: calc(40% - 20px);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: auto 1%;
}

.add-other-data input,
.add-other-data select {
  width: 50%;
  height: 36px;
  border: none;
  border-radius: 10px;
  background-color: #f8feff;
  border: 1px solid transparent;
  box-shadow: inset 1px 1px 3px 1px rgba(60, 60, 60, 0.1);
  font-size: 16px;
  font-weight: 500;
  font-family: sans-serif;
  padding-left: 20px;

  margin-left: 30px;
}

.add-other-data input:focus {
  outline: none;
  border: 1px solid #f3b300;
}

.add-other-data h3 {
  margin: 0;
  font-size: 18px;
  font-weight: 500;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.Individual-data-update-buttons button {
  width: 25%;
  height: 36px;
  border: none;
  border-radius: 10px;
  background-color: #f3b300;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  cursor: pointer;

  margin-top: 10px;
}

.popUp-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.3s ease;
}

.popUp-container {
  background-color: #fff;
  padding: 20px;
  border-radius: 12px;
  width: 80%;
  max-width: 490px;
  box-shadow: inset 0px 0px 15px rgba(0, 0, 0, 0.4);
  transition: transform 0.5s ease;
  animation: animate 0.5s 0s 1 forwards ease-in-out;
}

@keyframes animate {
  0% {
    opacity: 0;
    transform: scale(0.3);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.popUp-container:hover {
  transform: scale(1.02);
  box-shadow: 0px 0px 15px rgba(255, 217, 0, 0.4);
}

.popUp-Upper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.popUp-Upper span {
  font-size: 16px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.popUp-lower {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
}

.popUp-Heading {
  font-size: x-large;
  text-align: center;
  font-weight: bold;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.popUp-Upper, .popUp-lower {
  margin-bottom: 20px;
}

.popUp-Upper div, .popUp-lower div {
  margin-bottom: 10px;
}

.promoteBtn {
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 4px 2px;
  border-radius: 10px;
  border: none;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  background-color: #f3b300;
  color: white;
  transition: background-color 0.3s ease;
}

.cancelBtn{
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 4px 2px;
  border-radius: 10px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  background-color: #dbdbdb;
  color: black;
  border: 2px solid rgb(183, 183, 183);
  transition: background-color 0.3s ease;
}

.promoteBtn:hover {
  background-color: #c99402;
}

.cancelBtn:hover {
  background-color: #ededed;
}

.debard-dropdown{
  align-items: center;
  justify-content: space-between;
  background-color: #f8feff;
  border: solid 1px transparent;
  border-radius: 10px;
  padding: 9px 10px;
  color: #f3b300;
  font-family: inherit;
  font-size: 18px;
  z-index: 10;
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 150%;
  box-shadow: 0 0 10px #ccc;
  left: -20px;
}

.debard-dropdown-button{
  width: 100%;
  padding: 5px;
  text-align: left;
  background-color: white;
  border: none;
  border-bottom: 1px solid #ccc;
  cursor: pointer;
  font-size: 15px;
  transition: background-color 0.3s ease;
}

.debard-dropdown-button:hover{
  background-color: #dbdbdb;
}

.updateStyle{
  margin: 0 !important;
  margin-bottom: 30px !important;
  padding: 10px !important;
}

.debard-list-export{
  gap: 10px;
  justify-content: center;
  align-items: center;
  display: flex;
  padding: 10px 30px;
  transform: translateY(-11px);
  border-radius: 10px;
  font-size: 18px;
  font-weight: 600;
}