.complaintPage{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    width: 100%;
}

.complaintHeading{
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 20px;
}

.complaintContainer{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 90%;
    margin-bottom: 20px;
}

.complaintContainer .complaintRightPart{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 75%;
    margin-right: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
.complaintRightPart{
  max-height: 90vh;
  height: 90vh;
  min-height: fit-content;
}

.complaintContainer .complaintLeftPart{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 25%;
    margin-left: 20px;
}

.complaintUpdateStatusCont{
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 18px;
  margin: 12px 0px;
}

.buttonCont9{
  display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}

.unresolved{
  background-color: rgba(255, 168, 168, 0.308);
}

.inreview{
  background-color: rgba(164, 206, 255, 0.308);
}

.resolved{
  background-color: rgba(157, 255, 157, 0.308);
}

.complaintDetails{
  width: 700px;
  max-width: 70vw;
  height: auto;
  max-height: 80vh;
  overflow: auto;
  margin: 0 auto;

  position: fixed;
  top: 53%;
  left: 50%;
  transform: translate(-50%, -50%);

  background-color: #fff;
  box-shadow: 5px 5px 100px 100px rgba(49, 49, 49, 0.2);
  border-radius: 20px;
  padding: 20px;
  z-index: 99;
}

.complaintDetailsHeading{
  font-size: 24px;
  font-weight: bold;
  border-bottom: 1px solid #000;
  margin-bottom: 20px;
  position: relative;
}

.complaintDetailsHeading>button{
  position: absolute;
  top: 2px;
  right: 5px;
  background-color: transparent;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

.complaintDetailsContent{
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.complaintTopPart{
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 10px;
}

.complaintDisc{
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
}

.subjectTitle{
  color: rgb(0, 94, 255);
  text-decoration: underline;
  cursor: pointer;
}