@font-face {
  font-family: 'CustomFont';
  src: url('./assets/fonts/F25_Bank_Printer.woff') format('woff'),
    url('./assets/fonts/F25_Bank_Printer.woff2') format('.woff2'),
    url('./assets/fonts/F25_Bank_Printer.ttf') format('truetype'),
    url('./assets/fonts/F25_Bank_Printer.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: 'CustomFont', sans-serif;
}
