.passwordChange {
  width: 90%;
  height: 80vh;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  box-shadow: 1px 1px 10px 2px rgba(49, 49, 49, 0.1);
  border-radius: 20px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.passwordChange-container {
  width: 96%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.passwordChange-title {
  width: 100%;
  height: 60px;

  display: flex;
  justify-content: center;
  align-items: center;

  border-bottom: 2px solid #181818;
  margin-bottom: 20px;
}

.passwordChange-title h2 {
  margin: 0;
  font-size: 24px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;

  color: #181818;
}

.passwordChange-content {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.passwordChange-content form {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.passwordChange-content .form-group {
  width: 60%;
  height: 80%;
}

.passwordChange-content .form-group-element {
  width: 100%;
}

.passwordChange-content h5 {
  font-size: 18px;
  margin: 10px 0;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  text-align: left;
}

.form-group-element-title {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.passwordChange-content input {
  width: calc(100% - 20px);
  height: 40px;
}

.passwordChange-content a{
    text-decoration: none;
    color: blue;
    font-size: 18px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    text-align: left;
}

.passwordChange-content button {
  width: 30%;
  height: 46px;

  border: none;
  border-radius: 20px;

  background-color: #f3b300;
  color: #fff;

  font-size: 18px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;

  margin-bottom: 20px;
}
