.ratingDetailsCont {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
  gap: 40px;
}

.ratingSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.ratingDetailTableCont {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.ratingDetailTable {
  width: 100%;
  border-collapse: separate;
  margin-top: 20px;
}

.ratingDetailHead {
  background: #f3b300;
  color: white;
  font-size: 16px;
}

.ratingDetailHead > tr > th {
  padding: 8px 8px;
  text-align: center;
}

.ratingDetailBody > tr:nth-child(odd) {
  background: #f3f3f3;
}

.ratingDetailBody > tr:nth-child(even) {
  background: #ffffff;
}

.ratingDetailBody > tr > td {
  padding: 8px 8px;
  text-align: center;
}

.colHead {
  background: #f3b20063;
  font-weight: 500;
}

.categoryTitle {
  font-size: 18px;
  font-weight: 600;
  margin-top: 10px;
}
