.dashboard-header {
  display: flex;
  width: 100%;
  height: 80px;
  box-shadow: 1px 1px 10px 2px rgba(49, 49, 49, 0.1);
}

.complaints-div {
  width: 90%;
  margin: 0 auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  box-shadow: 1px 1px 10px 2px rgba(49, 49, 49, 0.1);
  border-radius: 20px;
}

.complaints-body {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.complaints-body h4 {
  margin: 10px 0;
  padding: 0;
}

.complaints-body-header {
  width: 96%;
  height: 10%;
  background: #f3b300;
  display: flex;
  justify-content: space-between;
  align-items: center;

  border-radius: 10px 10px 0 0;
  color: #fff;
}
.complaints-header-item,
.complaints-child-item {
  width: 15%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.complaints-header-item:nth-child(1),
.complaints-child-item:nth-child(1) {
  width: 20%;
  justify-content: space-evenly;
  align-items: flex-start;
  margin-left: 3%;
}
.complaints-header-item:nth-child(4),
.complaints-child-item:nth-child(4) {
  width: 30%;
}
.complaints-child-item:nth-child(4) h4 {
  width: 90%;
  text-align: justify;
  font-size: 16px;
}

.complaints-body-contents {
  width: 96%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  overflow-y: auto;
  scroll-behavior: smooth;
}
.complaints-body-contents::-webkit-scrollbar {
  width: 4px;

  background: transparent;
}
.complaints-body-contents::-webkit-scrollbar-thumb {
  background: #f3b300;
  border-radius: 8px;
}
.complaints-child {
  width: 100%;
  height: auto;
  background: #d8e7f5;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  margin-bottom: 10px;
}
.complaints-child:first-child {
  margin-top: 20px;
}
.complaints-child:last-child {
  margin-bottom: 25px;
}

.complaints-child h4 {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  text-align: left;
  font-weight: 500;
}
.complaints-child p {
  margin: 0;
  margin-bottom: 10px;
  font-size: 14px;
}

.upvote-btn {
  width: 40px;
  height: 30px;
  border-radius: 8px;
  margin-top: 10%;
  margin-bottom: 5%;
  font-size: 16px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 500;
  text-align: center;
}
.upvoted {
  background-color: #5a6dff;
  content: "✔️";
}

.mrk-apr-btn {
  width: 60%;
  height: 40px;
  border-radius: 10px;
  margin-top: 10%;
}

@media screen and (max-width: 768px) {
  .complaints-child {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .complaints-header-item,
  .complaints-child-item {
    width: 96%;
    margin: 0 auto;
  }
  .complaints-body-contents {
    width: 100%;
    height: auto;
    overflow-y: visible;
    scroll-behavior: auto;
  }
  .complaints-body-contents::-webkit-scrollbar {
    display: none;
  }
  .complaints-child {
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
  }
  .complaints-child:first-child {
    margin-top: 10px;
  }
  .complaints-child:last-child {
    margin-bottom: 20px;
  }
  .upvote-btn {
    margin-top: 5%;
    margin-bottom: 2%;
  }
  .mrk-apr-btn {
    width: 80%;
    margin-top: 5%;
  }
}
