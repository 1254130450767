.terms-popup {
  width: 70% !important;
  height: 90% !important;
}
.terms-contents {
  width: 94%;
  height: 95%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: 0 auto;

  overflow-y: scroll;
}
