.student-list-body {
  width: 100%;
  height: 6vh;

  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;

  border-bottom: 2px solid #fff;
  background-color: #d9f9ff;
  color: #4e4e4e;
}

.student-list-body:hover {
  background-color: #f8feff;
}

.header-element h3 {
  margin: 0;
  padding: 0;
  font-size: 5px;
  text-align: center;
}

.header-element a {
  text-decoration: none;
  font-size: 16px;

  font-weight: 600;
  color: rgb(41, 41, 249);
  margin: 0 0;
}
