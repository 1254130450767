.course-table {
  width: 100%;
  height: 100%;
  border: 2px solid #4e4e4e;
  border-radius: 20px;
}
.course-table-header,
.course-table-body,
.course-table-footer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #4e4e4e;
}

.course-table-body {
  flex-direction: column;
  border-bottom: transparent;
}

.course-table-header {
  height: 4vh;
}
.course-table-footer {
  height: 4vh;
  border-bottom: transparent;
}

.course-table-body-items {
  width: 100%;
  height: 4vh;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #4e4e4e;
}

.course-table-header-item,
.course-table-body-item,
.course-table-footer-item {
  height: 100%;

  display: flex;
  justify-content: space-around;
  align-items: center;

  border-right: 2px solid #4e4e4e;
}
.course-table-footer-item:first-child {
  width: calc(62% - 4px);
}
.course-table-footer-item:last-child {
  width: 38%;
}

.course-table-header-item:last-child,
.course-table-body-item:last-child,
.course-table-footer-item:last-child {
  border-right: transparent;
}

.sr-no {
  width: calc(8%);
}
.c-code {
  width: calc(12%);
}
.c-name {
  width: calc(42%);
  overflow: hidden;
}
.points {
  width: calc(5%);
}
.c-type {
  width: calc(18%);
}

.course-table h4 {
  font-size: 18px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu,
    Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

  font-weight: 500;
  margin: 0;
}

.course-table-body-item h4 {
  font-size: 15px;
  font-weight: 400;
  line-height: 15px;
}
