.top-div {
  width: 100%;
  margin-top: 60px;
  margin-bottom: 50px;

  display: flex;
}

.mid-div {
  width: 100%;
  margin-top: 50px;
  margin-bottom: 50px;

  display: flex;
}

.student-list-bodies {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.table-div {
  width: 90%;
  margin: auto;

  box-shadow: 1px 1px 10px 2px rgba(49, 49, 49, 0.1);
  border-radius: 20px;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin-bottom: 50px;
}
.student-list {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.student-list h3 {
  margin: 0;
  font-size: 16px;
  font-weight: 500;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
.student-list-header {
  width: 100%;
  height: 6vh;

  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;

  border-bottom: 2px solid #fff;
  background-color: #f3b300;
  color: #fff;
  border-radius: 20px 20px 0 0;
}


.header-element {
  width: 8%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  border-right: 2px solid #fff;
}
.header-element:first-child {
  width: 4%;
}
.header-element:nth-child(3) {
  width: 20%;
  overflow: hidden;
}
.header-element:nth-child(10) {
  width: 10%;
  overflow: hidden;
}
.header-element:last-child {
  border-right: transparent;
}

.pagination {
  width: 100%;
  height: 5vh;

  display: flex;
  justify-content: center;
  align-items: center;

  border-top: 2px solid #fff;
  background-color: #f8feff;
  color: #4e4e4e;
  border-radius: 0 0 20px 20px;
}

.pagination button {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: none;
  background-color: #f3b300;
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  margin: 0 10px;
}
.pagination button:hover {
  background-color: #f8feff;
  color: #f3b300;
  border: 2px solid #f3b300;

  cursor: pointer;
}
.pagination p {
  font-size: 20px;
  font-weight: 500;
  margin: 0 10px;
}

.result-footer {
  width: 90%;
  margin: auto;
  height: 20vh;

  box-shadow: 1px 1px 10px 2px rgba(49, 49, 49, 0.1);
  border-radius: 20px;

  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 60px;
}

.result-footer-title {
  width: 50%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  border-right: 1px solid black;
  background-color: #fff;
  border-radius: 20px 0 0 20px;
}

.result-footer-title h2 {
  margin-bottom: 0;
}

.result-footer-title p {
  font-size: 18px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 400;

  margin-top: 10px;
}

.result-footer-button {
  width: 50%;
  height: 100%;
  background-color: #f8feff;
  border-radius: 0 20px 20px 0;

  display: flex;
  justify-content: space-around;
  align-items: center;
}

.result-footer-button button {
  width: calc(50% - 10px);
  height: 50px;
  border: none;
  border-radius: 10px;
  background-color: #f3b300;
  color: white;
  font-weight: 500;
  font-size: 18px;
  font-family: sans-serif;

  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.result-footer-button button:hover {
  background-color: #5a6dff;

  cursor: pointer;
  transition-duration: 1s;
  transition: ease-in-out;
}

.header-element h3 span {
  font-weight: 400;
}
