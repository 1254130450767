.actions-div {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 40px;
}

.row {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.action-div {
  background: #ffffff;
  box-shadow: 1px 1px 10px 2px rgba(49, 49, 49, 0.1);
  border-radius: 20px;
  height: 40vh;
  width: calc(50% - 20px);

  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.action-div:hover {
  cursor: pointer;
  transform: scale(1.025);
  box-shadow: 2px 2px 10px 4px #d8e7f5;
  transition: ease-in-out;
}

.fee-div,
.register-div,
.result-div,
.docs-div {
  height: 80%;
  width: 95%;
  background: #d8e7f5;
  border-radius: 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  text-align: center;
}

.action-title {
  margin: auto;
}

.action-title h1 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu,
    Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: auto;
  text-align: center;

  background: linear-gradient(90deg, #1461ff 50.2%, #1b2767 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.action-btn a {
  text-decoration: none;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu,
    Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  text-align: center;
  color: #03b484;
}

.action-btn a:hover {
  cursor: pointer;
  font-weight: 500;
}

/* ----- Responsive ----- */

@media (max-width: 480px) {
  .actions-div {
    width: 100% !important;
    height: 100% !important;
    margin-top: 50px;
  }
  .actions-div .row {
    flex-direction: column;
    align-items: center;
  }

  .action-div {
    width: 100%;
    margin-bottom: 25px;
  }
}
