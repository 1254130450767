.search-student {
  width: 90%;
  margin: auto;
  box-shadow: 1px 1px 10px 2px rgba(49, 49, 49, 0.1);
  border-radius: 20px;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.title,
.search-div,
.filter-div {
  width: 96%;
  margin: auto;
}

.search-student .title {
  border-bottom: 2px solid #4e4e4e;
}

.search-div,
.filter-div {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.sub-title {
  margin-top: 10px;
}

.sub-title h3 {
  font-size: 18px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu,
    Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 500;
  margin: 10px, 0;
}
.sub-title h3 span {
  font-weight: 400;
}

.search-items,
.filter-items {
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  margin-bottom: 20px;
}

.search-item,
.filter-item {
  font-size: 20px;
  font-family: sans-serif;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.search-item select,
.filter-item select,
.search-item input {
  height: 36px;
  width: calc(50% - 10px);
  display: block;
  padding: 5px;
  border-radius: 10px;
  background-color: #f8feff;
  border: 1px solid transparent;
  box-shadow: inset 1px 1px 3px 1px rgba(60, 60, 60, 0.1);
  font-size: 16px;
  font-family: inherit;
  /* text-transform: capitalize; */
  color: rgb(225, 183, 0);
  font-weight: 400;
  transition: all 0.5s ease;
}
.search-item input {
  height: 24px;
}

.search-item select:focus,
.filter-item select:focus,
.search-item input:focus {
  border: 2px solid #f3b300;
}

.search-item h4,
.filter-item h4 {
  font-size: 18px;
  font-family: sans-serif;
  font-weight: 500;
  margin-right: 10px;
}

.btn-div {
  justify-content: flex-end;
}

.search-item button,
.filter-item button {
  width: calc(50% - 10px);
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 10px;
  background-color: #f3b300;
  color: white;
  font-weight: 500;
  font-size: 20px;
  font-family: sans-serif;
}

.btn-div img {
  height: 18px;
  align-items: center;
  margin-left: 10px;
}
