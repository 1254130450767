.profilePageCont{
    margin: 0;
    padding: 0;
    height: 100%;
    background-color: #f5f5f5;
    width: 100%;
}

.banner{
    background-color: #D3B62A;
    height: 30vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    z-index: 2;
}

.profileCont{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin: auto;
    width: 90%;
    z-index: 1;
    background: transparent;
    gap: 20px;
    transform: translateY(-100px);
}

.leftProfile{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    width: 30%;
    background: white;
    border: 1px solid #c0c0c0;
    border-radius: 10px;
    z-index: 10;
}

.rightProfileData{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    width: 70%;
    min-height: 500px;
    height: 100%;
    background: white;
    border: 1px solid #c0c0c0;
    border-radius: 10px;
    z-index: 10;
}

.profileImageCont{
  position: relative;
  /* width: 100%; */
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  margin: 20px 0px;
  margin-top: 25px;
}

.imageCont{
  width: 165px;
  height: 165px;
  /* border-radius: 10px; */
  border-radius: 50%;
  overflow: hidden;
  border: 5px solid white;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.245);
  position: relative;
  z-index: 10;
}

.imageCont img{
  width: 100%;
  /* height: 100%; */
  object-fit: cover;
  cursor: pointer;
}

.profilePreview{
    position: absolute;
    width: 100vw;
    height: 100vh;
    background: rgba(46, 46, 46, 0.497);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
    backdrop-filter: blur(2px);
}

.imageContPreview{
    width: 300px;
    height: 400px;
    border-radius: 5px;
    overflow: hidden;
    transform: translateY(-5vh);
    box-shadow: 0 5px 20px rgba(255, 255, 255, 0.292);
}

.imageContPreview img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: pointer;
}

.imageEdit{
    position: absolute;
    bottom: 10%;
    right: 2%;
    background: #FFE607;
    border: 4px solid white !important;
    color: black;
    border-radius: 50%;
    cursor: pointer;
    z-index: 100;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.nameEmailCont{
  text-align: center;
}

.nameEmailCont>.name1{
  font-family: "Mochiy Pop One", sans-serif;
    font-size: 20px;
    width: 100%;
}

.nameEmailCont>.email{
  font-family: "Akaya Kanadaka", system-ui;
  font-size: 18px;
}

.registrationStatus{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 20px 0px;
    font-size: 24px;
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
    letter-spacing: 0.5px;
}

.complete{
    color: green;
}

.incomplete{
    color: red;
}

.basicDetails{
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: 20px 20px;
  gap: 10px;
  align-items: center;
  justify-content: center;
  font-family: "Alatsi", sans-serif;
  font-size: 18px;
}

.basicDetails>.details{
  /* width: 100%; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: #D0EFF9;
  border-radius: 5px;
  padding: 10px 15px;
}

.basicDetails>.label{
  font-weight: 800;
}

.switchBar{
  display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    gap: 10px;
    width: 100%;
    height: 80px;
    border-bottom: #c0c0c0 1px solid;
    font-size: 17px;
    font-weight: 500;
}

.switchBar>.items{
  height: calc(100% - 4px);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 10px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.currActive{
  /* border-bottom: 4px solid #D3B62A; */
  box-shadow: 0 5px 0px #D3B62A;
  background: #f5f5f5;
}

.profile_input{
  width: fit-content;
  padding: 5px 10px;
  border-radius: 5px;
  border: 1px solid #c0c0c0;
  font-size: 15px;
}

.profile_input:focus{
  outline: none;
  border: 1px solid #D3B62A;
}

.profile_edits{
  position: absolute;
  top: 10px;
  right: 15px;
}