.NewAdmissionProgress {
  width: 90%;
  height: 100%;
  margin: 0 auto;

  box-shadow: 1px 1px 10px 2px rgba(49, 49, 49, 0.1);
  border-radius: 20px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.NewAdmissionProgress h2 {
  margin: 20px 0;
  font-size: 24px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 500;
}

.status-div {
  width: 96%;
  margin: 0 auto;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 20px;
}

.status-element {
  width: calc(25% - 10px);
  height: 100%;

  background-color: #d8e7f5;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  border-radius: 15px;
}

.status-element h1,
.status-element h3 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;

  font-weight: 500;
  margin: 20px 0;
}

.status-element h3 {
  font-size: 20px;
  margin-bottom: 0;
}

.ap {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.form-completed {
  background-color: rgb(149, 255, 188);
}
.form-unverified {
  background-color: rgb(255, 255, 150);
}
.form-pending {
  background-color: rgb(255, 150, 150);
}

.ap img {
  width: 36px;
  height: 36px;
  object-fit: cover;

  border-radius: 50%;
}

.ap h3 {
  margin: 0;
}

.nslb {
  height: 40px;
}

.ap button{
  width: 100%;
  height: 100%;
  padding: 10px 20px;

  font-size: 18px;
  color: #1461ff;

  background: transparent;
  border: none;

  cursor: pointer;
}

.apLoading {
  width: 100%;
  height: 100%;
  padding: 10px 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  font-size: 15px;
  color: #959595;

  background: transparent;
  border: none;
}

.ap button:hover {
  background: #1461ff;
  color: #fff;
  transition: ease-in-out 0.3s;
}

.newAdmissionTableContainer {
  width: 90%;
  margin: 0 auto;
}

.newAdmissionTable{
    margin: auto;
    border-collapse: collapse;
    margin-top: 20px;
    margin-bottom: 0px;
    box-shadow: 1px 1px 10px 2px #3131311a;
    border-radius: 10px;
}

.newAdmissionBody>tr{
  background: #e2edf7a9;
}

.newAdmissionBody>tr:hover{
  background: #ffffff;
}

.newAdmissionRollFilter {
  height: 24px;
  width: 150px;
  display: block;
  padding: 5px;
  border-radius: 10px;
  background-color: #f8feff;
  border: 1px solid transparent;
  box-shadow: inset 1px 1px 3px 1px rgba(60, 60, 60, 0.1);
  font-size: 16px;
  font-family: inherit;
  /* text-transform: capitalize; */
  color: rgb(225, 183, 0);
  font-weight: 400;
  transition: all 0.5s ease;
}

.newAdmissionRollFilter:focus {
  border: 2px solid #f3b300;
}

.newAdmissionRollFilter::placeholder {
  color: #f3b2006d;
}

.newAdmissionRollFilter:active {
  border: 2px solid #f3b300;
}

.cv2{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  border-bottom: 2px solid #4e4e4e;
  margin-bottom: 30px;
}

.newAdmissionFilterContainer{
  margin-top: 0px !important;
}

.newAdmissionFilterTitle>p{
  font-size: 20px !important;
  font-weight: 500 !important;
  margin-bottom: 10px !important;
}

.closeReject{
  position: absolute;
    top: 0px;
    right: 10px;
    padding: 5px 8px !important;
    color: white;
    background: gray;
}