.banner-image,
.Applicant-Details,
.admission-form,
.NS-Fee-Payment {
  width: 90%;
  height: 100%;
  margin: 0 auto;

  box-shadow: 1px 1px 10px 2px rgba(49, 49, 49, 0.1);
  border-radius: 20px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.banner-image {
  height: 35vh;

  background-image: url("/public/assets/login-background.jpg");
  background-repeat: no-repeat;
  background-position: 50% 33%;
  background-size: cover;

  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.blur-overlay {
  width: 100%;
  height: 100%;
  border-radius: 20px;

  border: 2px solid #fff;
  background: linear-gradient(124deg, #fff 0%, rgba(255, 255, 255, 0.2) 100%);
  backdrop-filter: blur(4px);

  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.banner-message {
  width: 75%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.banner-message h1 {
  margin: 15px auto;
  font-size: 32px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;

  font-weight: 600;
  margin-top: 10px;

  background: linear-gradient(90deg, #1461ff 50.2%, #1b2767 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.banner-message h4 {
  margin: 10px auto;
  font-size: 18px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;

  color: #181818;
  font-weight: 400;
  text-align: center;
}

.Applicant-Details-Title,
.admission-form-title,
.NS-Fee-Payment-Title {
  width: 96%;
  height: 60px;

  display: flex;
  justify-content: center;
  align-items: center;

  border-bottom: 2px solid #181818;
}

.Applicant-Details-Title h2,
.admission-form-title h2,
.NS-Fee-Payment-Title h2 {
  margin: 0;
  font-size: 24px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;

  color: #181818;
}

.Applicant-Details-Content {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.content-div-row {
  width: 96%;
  height: 100%;
  margin: auto;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.content-div-element {
  width: calc(50% - 15px);
  height: 15vh;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  border-radius: 15px;
  color: #fff;
}

.content-div-element h4 {
  margin: 0;
  font-size: 18px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;

  color: #fff;
  font-weight: 500;
}

.content-div-element h4 span {
  font-weight: 400;
}

.content-div-left,
.content-div-right {
  width: 50%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.content-div-left {
  margin-left: 10px;
  text-align: left;
}
.content-div-right {
  margin-right: 10px;
  text-align: right;
}

.card-main {
  width: 100%;
  margin-top: 20px;
  margin-bottom: 10px;
  background: linear-gradient(90deg, #1461ff 50.2%, #1b2767 100%);
}

.card-1,
.card-2 {
  margin-top: 10px;
  margin-bottom: 10px;
  background: linear-gradient(135deg, #03b484 0%, #27a797 100%);
}

.card-3,
.card-4 {
  margin-top: 10px;
  margin-bottom: 25px;
  background: linear-gradient(135deg, #7b61ff 0%, #3b73b9 100%);
}

.card-main:hover,
.card-1:hover,
.card-2:hover,
.card-3:hover,
.card-4:hover {
  transform: scale(1.025);
  transition: all 0.2s ease-in-out;
}

.admission-form-content {
  width: 96%;
  height: 80vh;
  margin: auto;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.admission-form-menu {
  width: calc(20% - 20px);
  height: 80%;
  margin: auto 0;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-end;

  border-right: 1px solid #4e4e4e;
}

.form-menu-element {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.form-menu-element button {
  width: 40px;
  height: 40px;

  border: 1px solid #4e4e4e;
  border-radius: 50%;
  background-color: #fff;

  position: relative;
  right: -20px;
  cursor: pointer;
}

.step.active {
  border: 2px solid #f3b300;
}

.step.finished {
  background-color: #f3b300;
  color: #fff;
  border: 1px solid #f3b300;
}

.admission-form-fields {
  width: calc(75% - 20px);
  height: 80%;
  margin: auto 0;

  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.form-fields-element {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  /* display: none; */
}

.form-fields-element form {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;

  margin: 0;
}

.form-container {
  width: 100%;
  height: 80%;
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}

.file-loading{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: center;
  position: absolute;
  border-radius: 10px;
  padding: 10px;
  background-color: rgba(48, 48, 48, 0.297);
}

.form-groups-row {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.form-group {
  width: calc(50% - 10px);
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.left-group {
  align-items: flex-start;
}
.right-group {
  align-items: flex-end;
  text-align: right;
}

.form-group label {
  margin: 0;
  font-size: 18px;
  font-weight: 500;
}

.form-group input,
.form-group select {
  width: 80%;
  height: 32px;
  padding: 0 10px;

  border: 1px solid transparent;
  border-radius: 10px;

  background-color: #f8feff;
  color: #f3b300;
  font-size: 14px;
  font-weight: 400;

  box-shadow: inset 1px 1px 3px 1px rgba(60, 60, 60, 0.1);
}

.form-group input:focus,
.form-group select:focus {
  outline: none;
  border: 1px solid #f3b300;
}

.form-group input::placeholder,
.form-group select::placeholder {
  color: #4e4e4e;
  opacity: 0.5;
}

.required:after {
  content: "*";
  color: red;
}

.docs-uploading input {
  box-shadow: none;
  background-color: transparent;
  border: 1px solid #4e4e4e;
  color: #4e4e4e;
  height: auto;
  padding: 5px;
}

.form-fields-element button {
  width: 25%;
  height: 40px;

  border: none;
  border-radius: 10px;
  background-color: #f3b300;

  font-size: 18px;
  font-weight: 500;
  color: #fff;

  cursor: pointer;
}

/* ----- Responsive ----- */

@media (max-width: 480px) {
  .banner-message {
    width: 90%;
  }
  .banner-message h1 {
    font-size: 24px;
  }
  .banner-message h4 {
    font-size: 16px;
  }

  .Applicant-Details-Title h2,
  .admission-form-title h2,
  .NS-Fee-Payment-Title h2 {
    font-size: 20px;
  }

  .content-div-row {
    flex-direction: column;
  }

  .content-div-element {
    width: 100%;
    width: 100%;
    /* flex-direction: column; */
    /* justify-content: center; */
  }

  .content-div-element h4 {
    font-size: 16px;
    display: flex;
    flex-direction: column;
  }
  .content-div-element h4 span {
    overflow-x: scroll;
  }
  .card-3 {
    margin-bottom: 10px;
  }

  .admission-form-content {
    width: 100%;
    height: 100%;

    flex-direction: column;
  }

  .admission-form-menu {
    width: 80%;
    height: 30%;
    margin: 0;

    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;

    border-right: none;
    border-bottom: 1px solid #4e4e4e;

    margin-bottom: 40px;
  }

  .form-menu-element {
    flex-direction: column;
    align-items: center;
  }

  .form-menu-element button {
    right: 0;
    bottom: -20px;
  }

  .form-menu-element h3 {
    font-size: 14px;
    text-align: center;
    margin-bottom: 0;
  }

  .admission-form-fields {
    width: 94%;
    height: 60vh;
    margin: auto;

    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }

  .form-fields-element form {
    justify-content: space-evenly;
  }

  .form-group label {
    font-size: 14px;
  }

  .form-group input,
  .form-group select {
    font-size: 12px;
  }

  .form-fields-element button {
    width: 40%;
    height: 36px;
    font-size: 16px;
  }
}

/* .formVerified{
  opacity: 0.5;
} */

.formVerified::before{
  content: "Form Verified";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 24px;
  font-weight: 600;
  color: #ffffff;
  background-color: #4b4b4b92;
  backdrop-filter: blur(5px);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  cursor: not-allowed;
}