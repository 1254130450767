.ManageAdmins {
  width: 90%;
  margin: auto;

  box-shadow: 1px 1px 10px 2px rgba(49, 49, 49, 0.1);
  border-radius: 20px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.MA {
  width: 96%;
  margin: 0 auto;
}

.MA .result-content {
  height: 48px;
}

.MA .result-content button {
  width: 50%;
  height: 36px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 500;
}

.MA .result-content:last-child {
  margin-bottom: 25px;
}

.MA form {
  width: 96%;
  height: 90%;
  justify-content: space-evenly;
}

.student-assign {
  width: 90%;
  margin: 0 auto;
  height: 100%;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;

  box-shadow: 1px 1px 10px 2px rgba(49, 49, 49, 0.1);
  border-radius: 20px;
}

.student-assign .student-table {
  width: calc(75% - 20px);
  height: 100%;

  margin: 25px 10px;
}

.student-assign .allotment-btns {
  width: calc(25% - 20px);
  height: 280px;
  margin: 25px 10px;
}

.manageAdminSearchBar {
  width: 90%;
  margin: 0 auto;

  box-shadow: 1px 1px 10px 2px rgba(49, 49, 49, 0.1);

  border-radius: 20px;
}

.adminTableContainer {
  width: 90%;
  margin: 0 auto;
}

.manageAdminTable, .facultyStudentTable{
    margin: auto;
    border-collapse: collapse;
    margin-top: 20px;
    margin-bottom: 20px;
    box-shadow: 1px 1px 10px 2px #3131311a;
    border-radius: 10px;
}

.facultyStudentTable{
  height: 800px;
  min-height: fit-content;
  overflow: scroll;
}

.manageAdminBody>tr:nth-child(odd){
  background: #e2edf7a9;
}

.manageAdminBody>tr:nth-child(even){
  background: #ffffff;
}