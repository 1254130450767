.pdcont{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 20px;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    padding: 20px;
    width: 80%;
  }
  
.pdcont:hover{
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.pd-title{
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 20px;
    text-decoration: underline;
    text-align: center;
}

.pd-sec{
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.pd-sec>label{
  font-size: 16px;
  font-weight: 500;
}

.pdcont>button{
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
}

.pd-input{
  height: 24px;
    width: 100px;
    display: block;
    padding: 5px;
    border-radius: 10px;
    background-color: #f8feff;
    border: 1px solid transparent;
    box-shadow: inset 1px 1px 3px 1px rgba(60, 60, 60, 0.1);
    font-size: 16px;
    font-family: inherit;
    /* text-transform: capitalize; */
    color: rgb(225, 183, 0);
    font-weight: 400;
    transition: all 0.5s ease;
}