/* ContextMenu.css */
.context-menu {
  position: absolute;
  background-color: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  z-index: 1000;
}

.context-menu-item {
  padding: 10px 20px;
  cursor: pointer;
}

.context-menu-item:hover {
  background-color: #e0e0e0;
}
