.BonafideRequests-content {
  margin: 20px auto;
  flex-direction: column;
  justify-content: flex-start;
  overflow-y: scroll;
}

.bonafied-gateway-popup {
  width: fit-content;
  height: 80%;
  margin: 0 auto;

  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform-origin: 70% -20%;

  background-color: #fff;
  box-shadow: 10px 10px 100px 20px rgba(49, 49, 49, 0.2);
  border-radius: 20px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  padding: 0px 10px;
  scale: 1;

  z-index: 99;
  transition: scale 0.3s ease-out;
}

.bonafied-gateway-popup-minimized {
  scale: 0.25;
  /* top: 0;
  right: 0%; */
  transition: scale 0.3s ease-in, top 0.3s ease-in, right 0.3s ease-in;
}

.filter-popup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  box-shadow: 10px 10px 100px 20px rgba(49, 49, 49, 0.2);
  border-radius: 20px;
  backdrop-filter: blur(10px);
  z-index: 1010;
}

.generate-primary {
  background-color: #f3b300;
  margin: auto auto;
  padding: 3px 10px;
  border-radius: 3px;
  color: #fff;
}

.MuiDataGrid-columnHeaders {
  background-color: #f3b300 !important;
}

.MuiDataGrid-row:hover {
  background-color: #d8e7f5 !important;
}

.productListTable {
  margin: 10px 0px;
  margin-bottom: 15px;
  background-color: white;
  /* border: none !important; */
}

.BonafideRequests-content .result-content-item:nth-child(2) {
  width: 20%;
}
.BonafideRequests-content .result-heading-item:nth-child(2) {
  width: 20%;
}

.result-content-item button {
  width: 100%;
  height: 28px;
  margin: auto;

  border-radius: 8px;
}
.mark-as-done {
  border-color: #5a6dff;
  color: #5a6dff;
}
