.latest {
  width: 90%;
  margin: auto;
  box-shadow: 1px 1px 10px 2px rgba(49, 49, 49, 0.1);
  border-radius: 20px;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.title,
.files {
  width: 96%;
  margin: auto;
}

.title h2 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu,
    Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

  font-size: 24px;
  color: #202020;
}

.files {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 25px;
}

.file {
  height: 30vh;
  width: calc(25% - 10px);
  background-color: #d8e7f5;
  border-radius: 20px;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.icon,
.filename {
  height: 35%;
  width: 100%;

  display: flex;
  justify-content: space-around;
  align-items: center;
}

.icon img {
  height: 75px;
  box-shadow: 1px 1px 5px rgba(49, 49, 49, 0.25);
  border-radius: 10px;
}

.filename h1 {
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  text-align: center;

  background: linear-gradient(90deg, #1461ff 50.2%, #1b2767 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

/* ----- Responsive ----- */

@media (max-width: 480px) {
  .files {
    flex-direction: column !important;
    align-items: center;
  }

  .file {
    width: 90% !important;
    margin-bottom: 25px;
  }
}
