.reveiwDetailCont{
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.reviewSidebar{
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 20px;
  z-index: 1000;
  width: 20%;
  left: 0;
  height: calc(100vh - 80px);
  gap: 20px;
  margin: 0 auto;
  /* box shadow on right side */
  box-shadow: -1px 1px 10px 2px rgba(49, 49, 49, 0.1);
}

.facName{
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 20px;
  text-align: center;
  width: 100%;
  text-decoration: dotted underline;
}

.details7>span{
  font-weight: 500;
  font-size: 18px;
}

.reviewDashboard{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 80%;
  height: 100%;
  margin-left: calc(20% + 40px);
  padding: 30px;
  margin-bottom: 30px;
}