.FormsDocs {
  width: 90%;
  margin: auto;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.FormsDocs-box {
  width: calc(50% - 25px);
  box-shadow: 1px 1px 10px 2px rgba(49, 49, 49, 0.1);
  border-radius: 20px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.form-title,
.doc-title,
.contents {
  width: 92%;
  margin: auto;
}

.form-title,
.doc-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
}

.form-title h2,
.doc-title h2 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu,
    Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

  font-size: 24px;
  color: #202020;
}

.form-title button,
.doc-title button {
  height: 36px;
  width: 20%;
  border-radius: 10px;
  border: none;
  font-size: 16px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu,
    Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 500;
  color: white;
}

.contents {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  height: 100%;
  margin-bottom: 25px;
}

.form,
.doc {
  height: 60px;
  width: 100%;
  background-color: #d8e7f5;
  border-radius: 15px;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  margin-top: 10px;
  margin-bottom: 10px;

  text-overflow: clip;
}

.form a,
.doc a {
  text-decoration: none;
  font-family: sans-serif;
  font-size: 20px;
  font-weight: 500;

  color: blue;
  margin-left: 20px;
}

.form:hover,
.doc:hover {
  background-color: #f3b300;
  box-shadow: 1px 1px 10px 2px rgba(49, 49, 49, 0.1);
  transition: ease-in-out;
}
.form:hover a,
.doc:hover a {
  color: white;
  font-style: oblique;
}

/* ----- Responsive ----- */

@media (max-width: 480px) {
  .FormsDocs {
    flex-direction: column;
  }

  .FormsDocs-box {
    width: 100%;
    margin-bottom: 25px;
  }
}
