.personalPageCont {
  width: 100%;
  animation: fadeIn 0.5s ease;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.p_top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  padding-top: 20px;
  transition: all 0.5s ease;
  gap: 20px;
}

.p_top .p_left,
.p_top .p_right {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  width: 50%;
  border-radius: 5px;
  box-shadow: 1px 1px 5px 2px rgba(32, 32, 32, 0.156);
  background: #dff2fa;
  padding: 5px 0px;
  font-size: 18px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.p_top .p_left:hover,
.p_top .p_right:hover {
  box-shadow: 2px 2px 10px 5px rgba(32, 32, 32, 0.25);
}

.p_bottom {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0px;
  transition: all 0.5s ease;
  box-shadow: 1px 1px 5px 2px rgba(32, 32, 32, 0.156);
  background: #dff2fa;
  width: 94%;
  margin: 10px auto;
  border-radius: 5px;
  font-size: 18px;
  transition: all 0.3s ease;
  cursor: pointer;
}

.p_bottom:hover {
  box-shadow: 2px 2px 10px 5px rgba(32, 32, 32, 0.25);
}

.p_content {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 20px;
  gap: 8px;
}

.p_content > span {
  font-weight: 800;
}

.p_update {
  /* flex-direction: column; */
}
