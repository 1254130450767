@font-face {
  font-family: 'Printer';
  src: url('./assets/fonts/F25_Bank_Printer.woff') format('woff'),
    url('./assets/fonts/F25_Bank_Printer.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'Printer' sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body::-webkit-scrollbar {
  /* create simple scrollbar without frame */
  width: 8px;
  margin-left: 2px;
  background-color: #f8feff;
  box-shadow: inset 1px 1px 3px 1px rgba(60, 60, 60, 0.1);
}

body::-webkit-scrollbar-thumb {
  background: #f3b300;
  border-radius: 8px;
}
body::-webkit-scrollbar-thumb:hover {
  background: #5a6dff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
