.paysection {
  width: 90%;
  margin: auto;

  box-shadow: 1px 1px 10px 2px rgba(49, 49, 49, 0.1);
  border-radius: 20px;
}

.paysection-div {
  width: 96%;
  margin: auto;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.paysection-title {
  width: 100%;
  height: 60px;
  margin: auto;

  display: flex;
  justify-content: center;
  align-items: center;

  border-bottom: 2px solid #181818;
  margin-bottom: 20px;
}

.paysection-title h2 {
  margin: 0;
  font-size: 24px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu,
    Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

  color: #181818;
}

.content-div {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.filter-items {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  margin-bottom: 20px;
}

.filter-item {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.filter-item select {
  height: 36px;
  width: calc(50% - 10px);
  display: block;
  padding: 5px;
  border-radius: 10px;
  background-color: #f8feff;
  border: 1px solid transparent;
  box-shadow: inset 1px 1px 3px 1px rgba(60, 60, 60, 0.1);
  font-size: 16px;
  font-family: inherit;
  text-transform: capitalize;
  color: rgb(225, 183, 0);
  font-weight: 400;
  transition: all 0.5s ease;
}

.inputFieldOfAllotedMess {
  height: 36px;
  width: 100px;
  display: block;
  padding: 5px;
  border-radius: 10px;
  background-color: #f8feff;
  border: 1px solid transparent;
  box-shadow: inset 1px 1px 3px 1px rgba(60, 60, 60, 0.1);
  font-size: 16px;
  font-family: inherit;
  text-transform: capitalize;
  color: rgb(225, 183, 0);
  font-weight: 400;
  transition: all 0.5s ease;
}

.filter-item h4 {
  font-size: 18px;
  font-family: sans-serif;
  font-weight: 500;
  margin-right: 10px;
}

.btn-div {
  justify-content: flex-end;
}

.filter-item button {
  width: calc(50% - 10px);
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 10px;
  background-color: #f3b300;
  color: white;
  font-weight: 500;
  font-size: 20px;
  font-family: sans-serif;
}

.filter-item button:hover {
  background-color: #5a6dff;
}

.btn-div img {
  height: 18px;
  align-items: center;
  margin-left: 10px;
}

.result-items {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  margin-bottom: 30px;
}

.fee-table {
  width: calc(80% - 10px);
  height: auto;
  position: relative;

  border: 2px solid black;
  border-radius: 20px;

  text-align: left;
}

.fee-table h4 {
  margin-left: 5%;

  font-family: sans-serif;
  font-weight: 500;
}

.types {
  display: flex;
  flex-direction: column;

  width: 100%;
  margin: 0, 0;
}

.type {
  display: flex;
  flex-direction: row;

  width: 100%;
  margin: 0, 0;
}
.type:last-child {
  border-bottom: transparent;
}

.type-name {
  width: calc(25% - 0px);
  border-right: 2px solid black;
  border-bottom: 2px solid black;
}

.total-title {
  width: calc(70% - 0px);
  border: transparent;

  text-align: center;
}

.total-title h4 {
  margin-left: 0px;
}

.type-contents {
  width: calc(75% - 0px);

  display: flex;
  flex-direction: column;
}

.type-total {
  width: calc(30% - 0px);
}

.type-content {
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.type-content:last-child {
  border-bottom: transparent;
}

.content-count {
  width: calc(20% - 0px);
  border-bottom: 2px solid black;
}
.content-count {
  text-align: center;
}
.content-count h4 {
  margin-left: 0px;
}

.content-name,
.content-price {
  width: calc(40% - 0px);
  display: flex;
  align-items: center;
  border-left: 2px solid black;
  border-bottom: 2px solid black;
}

.total-value {
  width: 100%;
  border-bottom: transparent;
}

.payment-button {
  width: calc(20% - 10px);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;

  box-shadow: 1px 1px 3px 1px rgba(49, 49, 49, 0.2);
  border-radius: 20px;
  font-family: 'Roboto', sans-serif;
}

.payment-button button {
  width: calc(80% - 10px);
  height: 50px;
  border: none;
  border-radius: 10px;
  background-color: #f3b300;
  color: white;
  font-weight: 500;
  font-size: 20px;
  font-family: sans-serif;

  display: flex;
  flex-direction: row-reverse;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 15px;
}

.payment-button button:hover {
  background-color: #5a6dff;
}

.payment-title {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.payment-title h2 {
  margin: 0 0 15px 0;

  font-size: 24px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu,
    Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 500;
  text-align: left;

  color: #4e4e4e;
}

.payment-title h2 span {
  color: #f3b300;
}

.payment-title p {
  width: 80%;
  margin: auto;
  font-size: 16px;
  font-family: sans-serif;
  font-weight: 400;
  text-align: center;

  color: #4e4e4e;
}

.upload-items {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: left;

  box-shadow: 1px 1px 3px 1px rgba(49, 49, 49, 0.2);
  border-radius: 20px;
  margin-bottom: 35px;
}

.upload-title h4 {
  font-size: 20px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu,
    Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 500;
}
.upload-title h4 span {
  font-size: 18px;
  font-weight: 400;
}

input[type='file']::file-selector-button,
select {
  margin-right: 20px;
  border: 2px solid transparent;
  background-color: #f8feff;
  box-shadow: inset 1px 1px 3px 1px rgba(60, 60, 60, 0.1);
  padding: 8px 16px;
  border-radius: 10px;
  color: #f3b300;
  cursor: pointer;
  transition: background 0.2s ease-in-out;

  font-size: 16px;
  font-family: inherit;
  text-transform: capitalize;
  font-weight: 400;
}

input[type='file']::file-selector-button:hover {
  background: #5a6dff;
  border: 2px solid #5a6dff;
  color: #fff;
}

.text-input {
  margin-left: 10px;
  margin-right: 20px;
  border: 2px solid transparent;
  background-color: #f8feff;
  box-shadow: inset 1px 1px 3px 1px rgba(60, 60, 60, 0.1);
  padding: 10px 20px;
  border-radius: 10px;
  color: #f3b300;
  cursor: pointer;
  transition: background 0.2s ease-in-out;

  font-size: 16px;
  font-family: inherit;
  text-transform: capitalize;
  font-weight: 400;
}

.upload-title,
.upload-buttons {
  margin-left: 2%;
}

.upload-buttons {
  width: 96%;
  margin-right: 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 20px;
}

.upload-buttons button {
  width: 240px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 10px;
  background-color: #f3b300;
  color: white;
  font-weight: 500;
  font-size: 20px;
  font-family: sans-serif;

  margin-left: 20px;
  cursor: pointer;
}

.upload-buttons button:hover {
  background-color: #5a6dff;
}

#hostel-status {
  width: 96%;
  margin: 0, auto;

  background: #fff;
  border-top: 1px solid #4e4e4e;
}
#hostel-status span {
  font-weight: 500;
}

/* ----- Responsive ----- */

@media (max-width: 480px) {
  .paysection-title {
    height: 46px;
  }
  .paysection-title h2 {
    font-size: 20px;
  }

  .search-item,
  .filter-item h4 {
    font-size: 16px !important;
  }

  .filter-items {
    width: 96%;
    margin: auto;
  }

  .filter-item select {
    width: 52px !important;
    font-size: 12px !important;
    margin-right: 10px;
  }

  .filter-item button {
    width: 80px !important;
    font-size: 16px !important;
  }
  .filter-item button img {
    display: none;
  }

  .result-items {
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  .fee-table {
    width: 96%;
  }

  .fee-table h4 {
    font-size: 14px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .payment-button {
    width: 96%;
    margin-top: 20px;
    padding: 10px 0;
  }

  .payment-title h2 {
    font-size: 20px;
  }

  .payment-title p {
    font-size: 14px;
    margin-bottom: 10px;
  }

  .payment-button button {
    width: 50%;
    height: 36px;
    font-size: 16px;
  }

  .upload-items {
    width: 96%;
    margin: 0 auto;
    margin-bottom: 20px;
  }

  .upload-title h4 {
    font-size: 16px;
  }

  .upload-title,
  .upload-buttons {
    margin-left: 3%;
  }

  .upload-buttons {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
  }
  input[type='file']::file-selector-button {
    margin-left: 10px;
  }

  .upload-buttons input {
    margin-bottom: 10px;
  }

  .upload-buttons button,
  .upload-buttons select,
  .upload-buttons label {
    margin-left: 10px;
    margin-bottom: 10px;
  }

  .upload-buttons button {
    margin-top: 15px;
  }
}
