@font-face {
  font-family: 'Printer';
  src: url('../../assets/fonts/F25_Bank_Printer.woff') format('woff'),
    url('../../assets/fonts/F25_Bank_Printer.ttf') format('truetype');
}

.left-div {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  background: #ffffff;
  box-shadow: 1px 1px 10px 2px rgba(49, 49, 49, 0.1);
  border-radius: 20px;
  width: calc(30% - 20px);
  height: 120vh;
}

.left-div .image,
.details {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.details {
  text-align: left;
}

.img-section,
.name-section {
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.name-section {
  width: 90%;
}
.name-section h3 {
  font-family: cursive;
}

.img-section img {
  width: 100%;
  height: 100%;
  max-height: 200px;
  max-width: 200px;
  border-radius: 60px;
  margin-top: 20px;
  object-position: top;
  object-fit: cover;
}

.academic,
.line-div,
.contact,
.address {
  width: 80%;
  height: auto;
  margin: 0, auto;
  font-size: 20px;
  font-family: Printer, sans-serif;
}

.line-div img {
  width: 100%;
}

.name-section h3 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu,
    Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #202020;

  margin-bottom: 0px;
}
.details h4 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu,
    Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;

  color: #202020;
  margin: 5%;
}
.details h4 span {
  font-weight: 400;
  font-family: sans-serif;
  font-size: 18px;
  color: #202020;
}

/* ----- Responsive ----- */
@media (max-width: 480px) {
  .left-div {
    width: 100%;
    height: 15vh;
    margin-bottom: 50px;
  }

  .left-div .image {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .img-section,
  .name-section {
    width: 50%;
    height: 100%;
  }

  .img-section {
    justify-content: flex-start;
  }
  .name-section {
    justify-content: flex-end;
  }

  .img-section img {
    height: 60px;
    width: 60px;
    margin: 0 20px;
  }

  .name-section h3 {
    font-size: 22px;
    text-align: right;
    margin: 0 20px;
  }

  .details {
    display: none;
  }
}
