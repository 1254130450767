.hostel-management {
  width: 90%;
  margin: auto;

  box-shadow: 1px 1px 10px 2px rgba(49, 49, 49, 0.1);
  border-radius: 20px;
}
.btn-options {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.option-btn {
  margin-left: 20px;
  border: 2px solid transparent;
  background-color: #f8feff;
  box-shadow: inset 1px 1px 3px 1px rgba(60, 60, 60, 0.1);
  padding: 8px 16px;
  border-radius: 10px;
  color: #4e4e4e;
  cursor: pointer;
  transition: background 0.2s ease-in-out;

  font-size: 16px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 400;
}
.option-btn:hover {
  background: #5a6dff;
  color: #fff;
  transition: ease-in-out 0.2s;
}

.selected-btn {
  background: #f3b300;
  box-shadow: none;
  color: #fff;
  font-weight: 500;
}

.hostel-management-body .search-items{
  gap: 20px;
}

.hostel-management-body .search-item{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: fit-content;
}

.hostel-management-body .search-item select{
  width: 100%;
}

.hostel-management-body .search-item h4{
  margin: 0px;
}

.hostel-management-body .search-item button,
.hostel-management-body .filter-item button {
  width: 100%;
  justify-content: space-evenly;
}

.reset-btn {
  background-color: transparent !important;
  border: 2px solid #000 !important;
  color: #000 !important;
  cursor: pointer;
}
.reset-btn:hover {
  border: transparent !important;
  background-color: #e24825cd !important;
  color: white !important;
  transition: ease-in-out 0.2s;
}

.hostel-management-body .sub-title {
  width: 100%;
  height: 40px;
  margin: 20px 0;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.hostel-management-body .sub-title h2 {
  font-size: 22px;
  font-weight: 500;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  margin: 0;
}

.sub-title .btn-div {
  width: 20%;
}

.hostel-management-body {
  width: 100%;
  height: 100%;

  border-radius: 0 0 20px 20px;
}

.seat-allotment {
  width: 96%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 40px;
}

.allotment-requests {
  width: calc(75% - 20px);
  height: 100%;

  background-color: #fff;
  /* border: 3px solid #636363; */
  border-radius: 10px;
}
.request-list {
  width: 100%;
  height: 80vh;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 8px;
  /* overflow: auto; */
}
.request-list-body {
  width: 100%;
  height: 100%;
  /* overflow-y: auto;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 0 0 10px 10px; */
}
.request-list-body::-webkit-scrollbar {
  /* create simple scrollbar without frame */
  width: 4px;
  margin-left: 2px;
  background: transparent;
}
.request-list-body::-webkit-scrollbar-thumb {
  background: #f3b300;
  border-radius: 8px;
}
.request-list-header,
.request-list-element {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;

  width: 100%;
}
.request-list-header {
  background-color: #f3b300;
  color: #fff;
  height: 40px;
  margin-bottom: 20px;
  border-radius: 10px 10px 0px 0px;
}
.request-list-element {
  background-color: #d8e7f5;
  color: #4e4e4e;

  height: 36px;
  margin-bottom: 15px;
  font-size: 14px;
  font-weight: 400;
}
.request-list-header-item,
.request-list-element-item {
  width: 15%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.request-list-header-item:nth-child(3),
.request-list-element-item:nth-child(3),
.request-list-header-item:nth-child(11),
.request-list-element-item:nth-child(11) {
  width: 20%;
}
.checkbox-container {
  width: 30%;
  justify-content: flex-start;
}

.roomCont{
  width: 130px;
}

.request-list input {
  width: 33px;
  /* height: 18px; */
  margin: 0 5%;
  cursor: pointer;
  padding: 4px 7px;
}
.request-list input:checked {
  background-color: #f3b300;
  border: 2px solid #f3b300;
}

.request-list-element-item .roomInput {
  width: 75%;
  height: 20px;
  border-radius: 8px;
  border: none;
}
.request-list-element-item .roomBtn {
  width: 25%;
  height: 24px;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;

  color: #fff;
  border: none;
  border-radius: 30%;
  background-color: #f3b300;
}
.roomBtn:hover {
  background-color: #5a6dff;
  transition: ease-in-out 0.2s;
}

.allotment-actions {
  width: calc(25% - 20px);
  gap: 20px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.approval-btns,
.allotment-btns {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;

  box-shadow: 1px 1px 4px 1px rgba(70, 70, 70, 0.1);
  border-radius: 10px;
}

.approval-btns {
  height: calc(40% - 20px);
}
.allotment-btns {
  height: calc(60% - 20px);
}

.approval-btns h3,
.allotment-btns h3 {
  font-size: 18px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 500;
  margin: 10px auto;
  color: #4e4e4e;
}

.approval-btns .remarks {
  width: 90%;
  height: 60px;
  margin: 0 auto;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.approval-btns textarea {
  width: 60%;
  height: 42px;
  padding: 5px;
  border: none;
  background-color: #f8feff;
  box-shadow: inset 1px 1px 3px 1px rgba(60, 60, 60, 0.1);
  border-radius: 10px;
  resize: none;
  outline: none;

  font-size: 14px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 400;
  color: #f3b300;
}
.approve-reject-btns {
  width: 90%;
  height: 60px;
  margin: 0 auto;

  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}
.approve-reject-btns button,
.allotment-btns button {
  width: 40%;
  height: 36px;
  padding: 2px;
  border-radius: 10px;
  outline: none;
  cursor: pointer;
  transition: ease-in-out 0.2s;

  font-size: 14px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 500;
}

.allotment-btns form {
  width: 100%;
  height: 70%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.allotment-btns .formgroup {
  width: 90%;
  margin: 0 auto;
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.allotment-btns select {
  margin: 0;
}

.seat-allotment-history {
  width: 96%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: left;
  margin-bottom: 25px;
}
.seat-allotment-history h2 {
  margin-bottom: 20px;
  font-size: 20px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 500;
  text-align: left;
}

.request-list-history {
  width: 100%;
  height: 50vh;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px;
}

/* Allotment Highlights */

.allotment-highlights {
  width: 90%;
  height: 125px;
  margin: auto;

  box-shadow: 1px 1px 10px 2px rgba(49, 49, 49, 0.1);
  border-radius: 20px;

  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.allotment-highlight-group {
  height: 84%;
  margin: 8% 0;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  border-radius: 20px;
}

.allotment-highlight-group:first-child {
  background-color: #d8e7f5;
  width: calc(45% - 20px);
}

.allotment-highlight-group:last-child {
  width: calc(55% - 20px);
  gap: 10px;
}

.allotment-highlight {
  width: 18%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.allotment-highlight h1 {
  font-size: 32px;
  font-weight: 500;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  margin: 10px;
}
.allotment-highlight h4 {
  font-size: 16px;
  font-weight: 400;
  margin: 0;
}

.allotted-element {
  background-color: #d8e7f5;
  border-radius: 20px;
}

.hostelTimerSection {
  width: 100%;
  border-radius: 5px;
  box-shadow: 1px 1px 10px 2px rgba(49, 49, 49, 0.1);
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}