/* Style the form container */
.bonafide-application {
  margin: 30px auto;
  max-width: 600px;
  padding: 20px;
  background-color: #fff;

  box-shadow: 1px 1px 10px 2px rgba(49, 49, 49, 0.1);
  border-radius: 20px;
}

/* Style the form header */
.bonafide-application h1 {
  text-align: center;
  font-size: 36px;
  margin-bottom: 20px;
}

.bonafide-application form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

/* Style the form labels */
.bonafide-application label {
  display: block;
  margin-bottom: 8px;
  font-size: 18px;
}

/* Style the form inputs */
.bonafide-application input[type="text"],
.bonafide-application select,
.bonafide-application textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-bottom: 16px;
  font-size: 16px;
}

/* Style the form file input */
.bonafide-application input[type="file"] {
  margin-bottom: 16px;
}

/* Style the form submit button */
.bonafide-application button[type="submit"] {
  width: 30%;
  margin: 20px auto;
  padding: 12px 20px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-size: 18px;
}

/* Style the form error messages */
.bonafide-application input:invalid,
.bonafide-application select:invalid,
.bonafide-application textarea:invalid {
  border-color: #4e4e4e;
}

.bonafide-application input:invalid:focus,
.bonafide-application select:invalid:focus,
.bonafide-application textarea:invalid:focus {
  outline: none;
}

.bonafide-application input:invalid + span::before,
.bonafide-application select:invalid + span::before,
.bonafide-application textarea:invalid + span::before {
  content: "⚠ ";
  color: #ff0000;
}

.bonafide-application input:valid + span::before,
.bonafide-application select:valid + span::before,
.bonafide-application textarea:valid + span::before {
  content: "✓ ";
  color: #4caf50;
}

.bonafide-application span {
  font-size: 16px;
}
