.filter-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  /* max-width: 600px; */
  width: 100%;
}

.filter-row {
  display: flex;
  gap: 10px;
}

.filter-group {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.filter-group > label {
  margin-bottom: 5px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.filter-group input,
.filter-group select {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.checkbox-group {
  display: flex;
  flex-direction: column;
  gap: 5px;
  max-height: 190px;
  overflow-y: auto;
  border: 1px solid #ccc;
  border-radius: 3px;
  padding: 5px;
  opacity: 1;
  box-shadow: inset 0 8px 10px -6px rgba(0, 0, 0, 0.2), inset 0 -8px 10px -6px rgba(0, 0, 0, 0.2);
  transition: max-height 0.5s ease-in, opacity 1s ease-in-out;
}

.checkbox-group::-webkit-scrollbar {
  width: 0px;
}

.checkbox-group-hide {
  display: flex;
  flex-direction: column;
  gap: 5px;
  max-height: 0px;
  overflow-y: auto;
  border: 1px solid #ccc;
  border-radius: 3px;
  padding: 5px;
  opacity: 0;
  transition: max-height 0.5s ease-in, opacity 1s ease-in-out;
}

.checkbox-group-hide::-webkit-scrollbar {
  width: 0px;
}

.checkbox-label {
  display: flex;
  align-items: center;
  gap: 5px;
}

.filter-heading9 {
  font-size: 20px;
  font-weight: bold;
  border-bottom: 1px solid #ccc;
  margin-bottom: 10px;
  padding-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.filter-group > input {
  margin-right: 20px;
  border: 2px solid transparent;
  background-color: #f8feff;
  box-shadow: inset 1px 1px 3px 1px rgba(60, 60, 60, 0.1);
  padding: 8px 16px;
  border-radius: 10px;
  color: #f3b300;
  cursor: pointer;
  transition: background 0.2s ease-in-out;
  font-size: 16px;
  font-family: inherit;
  text-transform: capitalize;
  font-weight: 400;
}
