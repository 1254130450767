.hodMain{
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}

.hodHeading{
  display: flex;
  width: 88%;
  justify-content: space-between;
  align-items: center;
  margin-top: 50px;
}

.hodHeading p{
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 30px;
  font-weight: 500;
  margin: 0px;
  text-decoration: underline;
}

.backOption{
  display: flex;
  align-items: center;
  gap: 2px;
  cursor: pointer;
  position: absolute;
  left: 5%;
  top: 2%;
  border-radius: 5px;
  padding: 5px 10px;
  transition: all 0.3s ease;
  z-index: 10;
}

.backOption:hover{
  box-shadow: 0px 0px 5px 0px #00000086;
}

.table-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hod-student-table {
  border-collapse: separate;
  width: 90%;
  border: 1px solid #f3b200c5; /* 1px table border */
  border-radius: 10px; /* Curvy border */
  height: 70vh;
  overflow-y: auto;
  overflow-x: auto;
  display: block;
  position: relative;
  margin: 20px;
  margin-top: 10px;
}

.hod-student-head {
  position: sticky;
  top: 0;
}

.hod-student-table th,
.hod-student-table td {
  border-bottom: 1px solid #ddd;
  border-right: 1px solid #ddd;
  padding: 5px 8px;
}

.hod-student-table th {
  background-color: #f3b200;
  color: white;
}


/* Styling for the table header */
.hod-student-head th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: #f3b200;
  color: white;
}

.roll {
  width: 100px !important;
  min-width: 100px !important;
}

.name {
  width: 180px !important;
  min-width: 180px !important;
}

.courses {
  width: 200px !important;
  text-align: center;
  min-width: 200px !important;
}

.loadingContainer{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background-color: #0000000d;
}