.verification-request {
  width: 96%;
  height: auto;
  border-radius: 10px;
  box-shadow: 1px 1px 5px 2px rgba(49, 49, 49, 0.2);

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  margin-bottom: 30px;
}
.verification-request:hover {
  box-shadow: 2px 2px 10px 4px #d8e7f5;
  transition: ease-in-out;
}

.request-header,
.request-details,
.request-action {
  width: 98%;
  margin: auto;
  height: auto;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.request-header {
  height: 50px;
  border-bottom: 1px solid #4e4e4e;
}

.sender-info {
  width: calc(60% - 10px);
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.sender-info h3 {
  margin: 0px;
  font-size: 18px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu,
    Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 400;
}
.sender-info h3 span {
  font-weight: 500;
  margin-right: 20px;
}

.attachment {
  width: calc(40% - 10px);
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.attachment a {
  margin: 0px;
  text-decoration: none;
  color: #5a6dff;

  font-size: 18px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu,
    Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 400;
}
.attachment a:hover {
  font-weight: 500;
  cursor: pointer;
  text-decoration: underline;
}

.request-details {
  background-color: #f8feff;
}
.request-details h4 {
  margin: 20px 0;
  font-size: 18px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu,
    Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 400;
}

.request-action {
  height: 60px;
  border-top: 1px solid #4e4e4e;
}

.action-reason {
  width: calc(60% - 10px);
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.action-reason input {
  margin: 0;
  width: 50%;
}

.action-buttons {
  width: calc(40% - 10px);
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.action-buttons button {
  width: calc(30% - 10px);
  height: 36px;
  border-radius: 10px;
  font-weight: 500;
  font-size: 18px;
  font-family: sans-serif;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;

  margin-left: 20px;
}
.primary {
  border: none;
  background-color: #f3b300;
  color: white;
  cursor: pointer;
}
.primary:hover {
  background-color: #5a6dff;
  transition: 0.2s ease-in-out;
}
.secondary {
  background: transparent;
  color: #f3b300;
  border: 2px solid #f3b300;
}

.secondary:hover {
  border: transparent;
  background-color: #e24825cd;
  color: white;
}
