.courseContainer {
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  position: relative;
}

.forward{
  position: absolute;
  right: 20px;
  bottom: 10px;
  /* top: 50%;
  transform: translateY(-50%); */
}

.backward{
  position: absolute;
  right: 20px;
  bottom: 10px;
  cursor: not-allowed;
  padding: 8px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 10px;
  background-color: #f3b300;
  color: white;
  font-weight: 500;
  font-size: 20px;
  font-family: sans-serif;
  opacity: 0.5;
}

.courseTable {
  width: 60%;
  border-collapse: collapse;
}

.courseTable-head {
  background-color: #f8f8f8;
}

.courseTable-head th {
  padding: 10px;
  border: 1px solid #ddd;
  text-align: left;
}

.courseTable-body tr:nth-child(even) {
  background-color: #f2f2f2;
}

.courseTable-body td {
  padding: 10px;
  border: 1px solid #ddd;
  text-align: left;
}


.tableContainer{
  padding: 10px 20px;
}

.uploadMarks-table {
  border-collapse: separate;
  width: 100%;
  border: 1px solid #f3b200c5; /* 1px table border */
  border-radius: 10px; /* Curvy border */
  height: 60vh;
  overflow-y: auto;
  display: block;
  position: relative;
  
}

.uploadMarks-head{
  width: 100%;
}

.uploadMarks-head-row{
  /* display: flex; */
  width: 100%;
}

.uploadMarks-head th,
.uploadMarks-tbody td {
  /* border: 1px solid #000; */
  border-bottom: 1px solid #e8e8e8; /* 1px bottom border */
  border-right: 1px solid #e8e8e8; /* 1px right border */
  padding: 8px;
  text-align: center;
  margin: 0px;
}

.uploadMarks-head th {
  background-color: #f3b300; /* Header background color */
}

.uploadMarks-tbody td input {
  text-align: center;
  width: 95%;
  border: none;
  border-radius: 5px; /* Curvy border for input fields */
  padding: 5px;
  outline: none; /* Remove outline */
}

.uploadMarks-tbody td input[type="number"]::-webkit-inner-spin-button,
.uploadMarks-tbody td input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.uploadMarks-tbody td input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}

.first{
  border-top-left-radius: 10px;
}

.last{
  border-top-right-radius: 10px;
}

table::-webkit-scrollbar {
  /* create simple scrollbar without frame */
  width: 8px;
  height: 8px; /* Added for horizontal scrollbar */
  margin-left: 2px;
  background-color: #f8feff;
  box-shadow: inset 1px 1px 3px 1px rgba(60, 60, 60, 0.1);
}
table::-webkit-scrollbar-thumb {
  background: #f3b300;
  border-radius: 8px;
}
table::-webkit-scrollbar-thumb:hover {
  background: #5a6dff;
}

.bottom_section_part{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 10px 20px;
  margin-bottom: 50px;
}

.primary-btn, .bottom_section_part label {
  padding: 8px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 10px;
  background-color: #f3b300;
  color: white;
  font-weight: 500;
  font-size: 20px;
  font-family: sans-serif;
  cursor: pointer;
}

.primary-btn:hover, .bottom_section_part label:hover {
  background-color: #5a6dff;
}

.loading{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.237);
  z-index: 100;
}