.CourseDetailsPopUp {
  width: 80%;
  height: auto;
  margin: 0 auto;

  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  background-color: #fff;
  box-shadow: 10px 10px 100px 20px rgba(49, 49, 49, 0.2);
  border-radius: 20px;

  z-index: 99;
}

.course-popup-title {
  width: 100%;
  height: 60px;
  margin: 0;

  border-bottom: 2px solid #4e4e4e;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 30px;
}

.course-popup-title h2 {
  margin: 0;
  font-size: 24px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu,
    Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

  color: #4e4e4e;
}

.course-popup-title button {
  width: 32px;
  height: 32px;

  border: none;
  border-radius: 10px;
  background-color: #4e4e4e;
  color: #fff;

  font-size: 18px;
  font-weight: 500;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu,
    Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  text-align: center;

  cursor: pointer;
}

.course-popup-title button:hover {
  background-color: #fa3e3e;
}

.course-popup-content {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
