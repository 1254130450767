.records-upload {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.records-upload .data-upload-div:first-child {
  margin-bottom: 25px;
}

.date-input {
  margin-left: 10px;
  border: 2px solid transparent;
  background-color: #f8feff;
  box-shadow: inset 1px 1px 3px 1px rgba(60, 60, 60, 0.1);
  padding: 10px 20px;
  border-radius: 10px;
  color: #f3b300;
  cursor: pointer;
  transition: background 0.2s ease-in-out;

  font-size: 16px;
  font-family: inherit;
  text-transform: capitalize;
  font-weight: 400;
}

.paymentRecordTable{
  margin: auto;
    border-collapse: collapse;
    margin-top: 20px;
    margin-bottom: 20px;
    box-shadow: 1px 1px 10px 2px #3131311a;
    border-radius: 10px;
}

.paymentRecordBody>tr:nth-child(odd){
  background: #e2edf7a9;
}

.paymentRecordBody>tr:nth-child(even){
  background: #ffffff;
}