.upper-section-refund{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: 20px;
    width: 100%;
    gap: 20px;
}

.left-section-refund{
    display: flex;
    flex-direction: column;
    width: 75%;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    margin-left: 4%;
    height: 70vh;
}

.right-section-refund{
    display: flex;
    flex-direction: column;
    width: 25%;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    margin-right: 4%;
    padding: 20px;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 20px;
}

.lower-section-refund{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-top: 20px;
    width: 90%;
    gap: 20px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 20px;
    margin: 30px auto;
}

.updateRefundAmount{
    height: 24px;
    width: 90%;
    display: block;
    padding: 5px;
    border-radius: 10px;
    background-color: #f8feff;
    border: 1px solid transparent;
    box-shadow: inset 1px 1px 3px 1px rgba(60, 60, 60, 0.1);
    font-size: 16px;
    font-family: inherit;
    /* text-transform: capitalize; */
    color: rgb(225, 183, 0);
    font-weight: 400;
    transition: all 0.5s ease;
}

.updateRefundButton{
    padding: 10px 20px;
    border-radius: 8px;
    margin: 10px 0px;
    font-size: 16px;
}