.hodContainer {
  width: 90%;
  height: 100%;
  margin: 0 auto;

  box-shadow: 1px 1px 10px 2px rgba(49, 49, 49, 0.1);
  border-radius: 20px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.hodContainer h2 {
  margin: 20px 0;
  font-size: 24px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu,
    Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 500;
}

.filter-item2 {
  font-size: 20px;
  font-family: sans-serif;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  margin-bottom: 10px;
  margin-top: 5px;
}

.filterAttribute {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.filterAttribute p {
  margin: 2px;
  padding-left: 6px;
  font-size: smaller;
}

.below p {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu,
    Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: large;
  font-weight: 500;
}

.below-section {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.filters {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;
}

.SemCont {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  width: 100%;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.semEle {
  cursor: pointer;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu,
    Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 30px;
  text-align: center;
  padding: 25px 48px;
  border: none;
  border-radius: 10px;
  transition: all 0.3s ease;
  background: #f8feff;
  margin: 0px 10px;
  font-weight: 600;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
}

.semEle:hover {
  transform: scale(1.05);
  box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.1);
}
