.popUpOverlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
}

.profileUpdateContainer {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  width: 60vw;
  height: 60vh;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.updateBtnPart {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 20%;
}

.updateBtn {
  margin: 20px auto;
}

.profile-closebtn {
  position: absolute;
  top: 0;
  right: 0;
  margin: 10px;
  font-size: 30px;
  cursor: pointer;
  border: none;
  background-color: transparent;
}

.formPart {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80%;
  padding: 10px 0px;
}

.picPart {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 40%;
  height: 100%;
  gap: 20px;
}

.picWithName {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: center;
}

.profilePic_ {
  width: 180px;
  aspect-ratio: 5/6;
  border-radius: 5px;
}

.dataPart {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: flex-start;
  justify-content: flex-start;
  width: 60%;
  padding: 10px 20px;
  padding-right: 40px;
}

._data {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
}

._data > input,
._data > label {
  width: 65%;
}

._data > textarea {
  width: 95%;
}

._data > input,
._data > textarea {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  outline: none;
  font-size: 16px;
}

.imageCard {
  text-align: center;
}

.imageCard img {
  width: 600px;
}

/* show the file chosen below the input button */
.avatarButton {
  background-color: #f3b300;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
}

.avatarButton:hover {
  background-color: #2b80ff;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  opacity: 0.8;
}

.updateBtn {
  background-color: #f3b300;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  font-size: large;
}

.updateBtn:hover {
  background-color: #2b80ff;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  opacity: 0.8;
}
