.payHistory-section {
  width: 90%;
  margin: auto;

  box-shadow: 1px 1px 10px 2px rgba(49, 49, 49, 0.1);
  border-radius: 20px;
}

.payHistory-div {
  width: 96%;
  margin: auto;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.history {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.result-heading,
.result-content {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.result-heading {
  background-color: #f3b300;
  border-radius: 10px;
  color: #fff;
  width: 100%;
  height: 40px;
  margin-bottom: 20px;
}
.result-content {
  background-color: #d8e7f5;
  border-radius: 10px;
  color: #4e4e4e;

  height: 36px;
  margin-bottom: 15px;
}

.result-heading-item,
.result-content-item {
  width: calc(15% - 5px);
  text-align: center;
}
.index-column {
  width: 5%;
}
.TID {
  width: calc(20% - 5px);
}

/* ----- Responsive ----- */

@media (max-width: 480px) {
  .result-heading-item:nth-child(3),
  .result-heading-item:nth-child(6),
  .result-content-item:nth-child(3),
  .result-content-item:nth-child(6) {
    display: none;
  }

  .history h4 {
    font-size: 14px;
    text-overflow: clip;
  }

  .result-heading,
  .result-content {
    height: auto;
  }
}
