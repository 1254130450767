.quick-action {
  width: 90%;
  margin: auto;
  box-shadow: 1px 1px 10px 2px rgba(49, 49, 49, 0.1);
  border-radius: 20px;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.title,
.files {
  width: 96%;
  margin: auto;
}

.title h2 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu,
    Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

  font-size: 24px;
  color: #202020;
}

.files {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(20%, 1fr));
  /* grid-template-rows: 2fr; */
  gap: 20px;
  margin-bottom: 25px;
}

@media (max-width: 1000px) {
  .files {
    grid-template-columns: repeat(auto-fit, minmax(33.33%, 1fr));
  }
}

@media (max-width: 768px) {
  .files {
    grid-template-columns: repeat(auto-fit, minmax(40%, 1fr));
  }
}

@media (max-width: 500px) {
  .files {
    grid-template-columns: repeat(auto-fit, minmax(50%, 1fr));
  }
}

.file {
  height: 30vh;
  /* width: calc(25% - 10px); */
  width: 100%;
  background-color: #d8e7f5;
  border-radius: 20px;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.other-actions-file {
  height: 20vh;

  flex-direction: row;
  justify-content: space-evenly;
}

.file:hover {
  cursor: pointer;
  transform: scale(1.025);
  transition: ease-in-out;
}

.filename {
  height: 35%;
  width: 100%;

  display: flex;
  justify-content: space-around;
  align-items: center;
}

.oa-filename {
  height: 100%;
  width: 80%;
  margin: 0 auto;
}

.icon {
  height: 40%;
  width: 100%;
  position: relative;

  display: flex;
  justify-content: space-around;
  align-items: center;
}

.oa-icon {
  height: 100%;
  width: calc(30% - 10px);
}

.icon img {
  height: 75px;
  box-shadow: 1px 1px 5px rgba(49, 49, 49, 0.25);
  border-radius: 10px;
}
.notification {
  height: 24px;
  width: auto;
  background-color: #fa3e3e;
  border-radius: 15px;

  position: absolute;
  top: 0;
  right: 30%;

  z-index: 99;

  display: flex;
  justify-content: center;
  align-items: center;
}
.notification h2 {
  margin: 0;
  margin: 0 20px;

  color: #fff;
  font-size: 14px;
  font-family: roboto;
  font-weight: 600;
}

.filename h1 {
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  text-align: center;

  background: linear-gradient(90deg, #1461ff 50.2%, #1b2767 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
