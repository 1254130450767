.myCourse_table {
  border-collapse: separate;
  width: 100%;
  border-radius: 10px; /* Curvy border */
  max-height: 60vh;
  overflow-y: auto;
  display: block;
  position: relative;
  
}

.myCourse_head{
  width: 100%;
  position: sticky;
  top: 0;
}

.myCourse_head th,
.myCourse_body td {
  /* border: 1px solid #000; */
  border-bottom: 1px solid #e8e8e8; /* 1px bottom border */
  border-right: 1px solid #e8e8e8; /* 1px right border */
  padding: 8px 20px;
  text-align: center;
  margin: 0px;
}

.myCourse_head th {
  background-color: #f3b300; /* Header background color */
}