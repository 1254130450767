.gateway-popup {
  width: 60%;
  height: 80%;
  margin: 0 auto;

  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  background-color: #fff;
  box-shadow: 10px 10px 100px 20px rgba(49, 49, 49, 0.2);
  border-radius: 20px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  z-index: 99;
}

.gateway-popup-title {
  width: 100%;
  height: 60px;
  margin: 0;

  border-bottom: 2px solid #4e4e4e;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.gateway-popup-title h2 {
  margin: 0;
  font-size: 24px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu,
    Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

  color: #4e4e4e;
  margin-left: 3%;
}

.gateway-popup-title button {
  width: 32px;
  height: 32px;
  margin-right: 3%;

  border: none;
  border-radius: 10px;
  background-color: #4e4e4e;
  color: #fff;

  font-size: 18px;
  font-weight: 500;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu,
    Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  text-align: center;

  cursor: pointer;
}

.gateway-popup-title button:hover {
  background-color: #fa3e3e;
}

.gateway-popup-content {
  width: 94%;
  height: 90%;

  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;

  overflow-y: scroll;
}

.amount-input-div,
.payment-method-options {
  width: calc(50% - 10px);
  height: 60%;

  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;

  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu,
    Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.amount-input-div {
  border-right: 2px solid #4e4e4e;
}

.popup-form-group {
  width: 80% !important;
  height: 100%;
  margin: auto;

  align-items: flex-start;
}

.amount-input-div label {
  color: #4e4e4e;
  font-size: 24px !important;
  font-weight: 600 !important;
}

.amount-input-div input {
  width: 60%;
  height: 46px !important;

  border: 2px solid transparent !important;

  font-size: 20px !important;
  font-weight: 500 !important;
  text-align: center;

  margin-top: 20px;
}

.multiselectPopUp {
  margin-bottom: 25px !important;
}
.multiselectPopUp form {
  justify-content: flex-start !important;
}

.multiple-select {
  height: 100%;
}

.multiple-select select {
  height: 200px !important;
}

.amount-input-div input:focus {
  border: 2px solid #f3b300 !important;
}

.payment-method {
  width: 80%;
  height: 80px;

  border-radius: 10px;
  border: 2px solid transparent;
  box-shadow: 0px 0px 4px 2px rgba(49, 49, 49, 0.05);

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.payment-method h3 {
  font-size: 18px;
  font-weight: 600;
  color: #4e4e4e;
  text-align: center;
}
.payment-method h3 span {
  font-weight: 400;
}

.payment-method:first-child {
  border: 2px solid #f3b300;
}

.payment-method:hover {
  border: 2px solid #f3b300;
  background-color: #f3b300;

  cursor: pointer;
}

.payment-method:hover h3 {
  font-size: 20px;
  color: #fff;
  text-decoration: none;
}

/* ---- Responsive ----- */

@media (max-width: 480px) {
  .gateway-popup {
    width: 90%;
    height: 90%;
  }

  .gateway-popup-content {
    flex-direction: column;
  }

  .amount-input-div,
  .payment-method-options {
    width: 100%;
  }

  .payment-method-options {
    margin-bottom: 30px;
  }

  .amount-input-div {
    border-right: none;
    /* border-bottom: 2px solid #4e4e4e; */
  }
}
