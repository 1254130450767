.cv {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  border-bottom: 2px solid #4e4e4e;
  margin-bottom: 30px;
}

.title-left-div,
.title-right-div {
  width: calc(50% - 10px);
}

.cv .filter-item {
  justify-content: flex-end;
}

.title-right-div select {
  width: 160px;
  margin-left: 10px;
}

.attachment button {
  font-size: 18px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 500;
  color: #1461ff;

  border: none;
  background-color: transparent;
  cursor: pointer;
}
