.top-div {
  width: 100%;
  margin-top: 60px;
  margin-bottom: 50px;

  display: flex;
}

.verification-requests {
  width: 90%;
  margin: auto;

  box-shadow: 1px 1px 10px 2px rgba(49, 49, 49, 0.1);
  border-radius: 20px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.verification-requests h2 {
  margin: 20px 0px;
  font-size: 24px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 500;
}

.verification-requests h2 span {
  font-weight: 400;
}
