.status-div2{
  width: 96%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  gap: 50px;
}

.popUp-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.3s ease;
  z-index: 100;
  backdrop-filter: blur(5px);
}

.popUp-container {
  background-color: #fff;
  padding: 20px;
  border-radius: 12px;
  width: 80%;
  max-height: 90vh;
  overflow-y: auto;
  overflow-x: auto;
  max-width: 80vw;
  box-shadow: inset 0px 0px 15px rgba(0, 0, 0, 0.4);
  transition: transform 0.5s ease;
  animation: animate 0.5s 0s 1 forwards ease-in-out;
  z-index: 1000;
  position: relative;
}

.close{
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

@keyframes animate {
  0% {
    opacity: 0;
    transform: scale(0.3);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.popUp-container:hover {
  transform: scale(1.02);
  box-shadow: 0px 0px 15px rgba(255, 217, 0, 0.4);
}

.popUp-Upper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.popUp-Upper span {
  font-size: 16px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.popUp-lower {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
}

.popUp-Heading {
  font-size: x-large;
  text-align: center;
  font-weight: bold;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.popUp-Upper, .popUp-lower {
  margin-bottom: 20px;
}

.popUp-Upper div, .popUp-lower div {
  margin-bottom: 10px;
}

.filterSection{
  width: 96%;
  display: flex;
  gap: 20px;
  margin: auto auto;
}

.filterItem>p{
  margin: 4px 0px;
}

.courseChoose{
  width: 96%;
  display: flex;
  flex-direction: column;
}

.courseChoose>p{
  margin: 4px 0px;
}

.dropdown {
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f8feff;
  border: solid 1px transparent;
  border-radius: 10px;
  padding: 9px 10px;
  color: #f3b300;
  font-family: inherit;
  font-size: 16px;
  box-shadow: inset 1px 1px 3px 1px rgba(60, 60, 60, 0.1);
  margin-bottom: 0px !important;
}

.dropdown-header {
  width: 75%;
  font-size: 16px;
  color: #f3b300;
  margin-bottom: 0px !important;
}

.dropdown-button {
  background-color: transparent;
  border: none;
  width: 10%;
}

.dropdown-item {
  position: relative;
  margin-top: 2px;
  top: 100%;
  background-color: #f8feff;
  gap: 2px;
  box-shadow: inset 1px 1px 3px 1px rgba(60, 60, 60, 0.1);
  border-radius: 8px;
  padding: 5px 5px;
  width: 61%;
  display: flex;
  flex-direction: column;
  max-height: 300px;
  overflow-y: auto;
  opacity: 0;
  animation: rotateX 400ms 60ms ease-out forwards;
  transform-origin: top center;
}

@keyframes rotateX {
  0% {
      opacity: 0;
      transform: rotateX(-90deg);
  }
  50% {
      transform: rotateX(-20deg);
  }
  100% {
      opacity: 1;
      transform: rotateX(0deg);
  }
}

::-webkit-scrollbar {
  /* create simple scrollbar without frame */
  width: 5px;
  height: 5px;
  background-color: #f8feff;
}

::-webkit-scrollbar-thumb {
  background: #f3b300;
  border-radius: 5px;
}

.dropdown-item-list {
  padding: 8px 5px;
  cursor: pointer;
  border-bottom: solid 1px #c8e0f9;
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 0px !important;
}

.dropdown-item-list:hover {
  background-color: #e5f8fb;
}

.checkbox-container {
  position: relative;
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-right: 8px;
  vertical-align: middle;
  user-select: none;
  transition: all 0.2s ease-in;
  margin-bottom: 0px !important;
}

.checkbox-input {
  /* opacity: 0; */
  width: 16px;
  height: 16px;
  /* position: absolute; */
  cursor: pointer;
}

.courseName{
  margin-bottom: 0px !important;
}

.assignedCoursesCont{
  width: 96%;
  /* display: flex;
  flex-direction: column;
  gap: 20px;
  margin: auto auto; */
}

.Assign{
  padding: 8px 25px;
  border-radius: 8px;
  font-size: 18px;
}

.disabled{
  cursor: not-allowed !important;
  opacity: 0.5;
}

.present{
  background-color: #e2effc;
}