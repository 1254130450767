.NewRegistrationPage {
  width: 100vw;
  height: 100vh;

  margin: 0;

  background-image: url("/public/assets/login-background.jpg");
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;

  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.overlay-lefttoright {
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, #181818 0%, rgba(24, 24, 24, 0) 100%);
  background-blend-mode: luminosity;

  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.welcome-message-section,
.new-login-section {
  width: 50%;
  height: 100%;

  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.welcome-message-section {
  color: #fff;

  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.welcome-message-content {
  width: 80%;
  height: 100%;
  margin: auto;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;

  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.welcome-message-content h1 {
  font-size: 48px;
  font-weight: 600;
  margin-top: 18px;
}

.welcome-message-content h3 {
  font-size: 24px;
  font-weight: 500;
}

.welcome-message-content p {
  font-size: 18px;
  font-weight: 400;
}

/* ----- Responsive ----- */

@media (max-width: 480px) {
  .overlay-lefttoright {
    flex-direction: column;
    background: linear-gradient(135deg, #181818 0%, rgba(24, 24, 24, 0) 100%);
  }

  .welcome-message-section {
    width: 100%;
    height: calc(40% - 20px);
  }
  .new-login-section {
    width: 100%;
    height: calc(60% - 20px);
  }

  .welcome-message-content {
    align-items: center;
    width: 90%;
  }

  .welcome-message-content h1 {
    font-size: 24px;
    font-weight: 600;
    margin-top: 10px;
  }

  .welcome-message-content h3 {
    font-size: 18px;
    font-weight: 500;
    margin: 10px 0;
  }

  .welcome-message-content p {
    font-size: 14px;
    font-weight: 400;
    text-align: justify;
    margin: 5px 0;
  }

  .new-login-section {
    flex-direction: column;
  }

  .new-login-section .new-login-content {
    width: 80%;
    height: 100%;
    margin: auto;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .new-login-section .new-login-content h1 {
    font-size: 32px;
    font-weight: 600;
    margin-top: 18px;
  }

  .new-login-section .new-login-content h3 {
    font-size: 18px;
    font-weight: 500;
  }

  .new-login-section .new-login-content p {
    font-size: 14px;
    font-weight: 400;
  }
}
