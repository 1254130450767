.otherPageCont {
  animation: fadeIn 0.5s ease;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.o_top {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 10px 20px;
  transition: all 0.5s ease;
  gap: 20px;
}

.o_title {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 5px;
  margin-left: 5px;
}

.o_top .o_leftPart {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  width: 50%;
}

.o_top .o_rightPart {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 10px;
  width: 50%;
}

.o_top .o_rightPart .o_upperHalf,
.o_top .o_rightPart .o_lowerHalf {
  width: 100%;
}

.o_top .o_leftPart .o_left,
.o_upperHalf .o_right,
.o_lowerHalf .o_right {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  border-radius: 5px;
  background: #dff2fa;
  box-shadow: 1px 1px 5px 2px rgba(32, 32, 32, 0.156);
  padding: 5px 0px;
  font-size: 18px;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
}

.o_leftPart .o_left:hover,
.o_top .o_right:hover {
  box-shadow: 2px 2px 10px 5px rgba(32, 32, 32, 0.25);
}

.o_bottom {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 8px 0px;
  transition: all 0.5s ease;
  box-shadow: 1px 1px 5px 2px rgba(32, 32, 32, 0.156);
  background: #dff2fa;
  width: 94%;
  margin: 10px auto;
  border-radius: 5px;
  font-size: 18px;
  transition: all 0.3s ease;
  cursor: pointer;
}

.o_bottom:hover {
  box-shadow: 2px 2px 10px 5px rgba(32, 32, 32, 0.25);
}

.o_content {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 20px;
  gap: 8px;
}

.o_content > span {
  font-weight: 800;
}

.o_update {
  /* flex-direction: column; */
}

.o_buttonSection {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  margin: 15px 0px;
  font-size: 16px;
}

.o_buttonSection > button {
  padding: 10px 30px;
  border: 2px solid #f3b300;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
}
