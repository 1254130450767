.fee-section-table {
  width: 100%;
  height: 100%;

  border: 2px solid black;
  border-radius: 20px;
}

.fee-section-table h4 {
  font-size: 16px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu,
    Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

  font-weight: 400;
  margin: 0;
}

.fee-types {
  display: flex;
  flex-direction: column;

  width: 100%;
  margin: 0, 0;
}

.fee-type {
  display: flex;
  flex-direction: row;

  width: 100%;
  margin: 0, 0;
}
.fee-type:last-child {
  border-bottom: transparent;
}

.fee-type-name {
  width: calc(25% - 0px);
  border-right: 2px solid black;
  border-bottom: 2px solid black;

  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.fee-total-title {
  width: calc(70% + 1px);
  border: transparent;

  text-align: center;
}

.fee-type-contents {
  width: calc(75% - 0px);

  display: flex;
  flex-direction: column;
}

.fee-type-total {
  width: calc(30% - 0px);
}

.fee-type-content {
  width: 100%;
  height: 4vh;

  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.fee-type-content:last-child {
  border-bottom: transparent;
}

.fee-content-count {
  width: calc(20% - 0px);
  border-bottom: 2px solid black;
}
.fee-content-count {
  text-align: center;
}
.fee-content-count h4 {
  margin-left: 0px;
}

.fee-content-name,
.fee-content-price {
  width: calc(40% - 0px);
  border-left: 2px solid black;
  border-bottom: 2px solid black;

  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.fee-total-title h4,
.fee-total-value h4 {
  font-size: 18px;
  font-weight: 500;
}

.fee-total-value {
  width: 100%;
  border-bottom: transparent;
}
