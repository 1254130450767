.highlights-div {
  height: 25vh;
}
.highlights-div {
  background: #ffffff;
  box-shadow: 1px 1px 10px 2px rgba(49, 49, 49, 0.1);
  border-radius: 20px;
  padding: 2%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.highlight-element-row {
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.highlight-element {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: calc(25% - 10px);
  align-items: center;
}
.highlight-element .icon-section {
  height: 120px;
  width: 120px;
  background-color: #d8e7f5;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-section h1 {
  font-family: Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  text-align: center;

  background: linear-gradient(90deg, #1461ff 50.2%, #1b2767 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.icon-section img {
  width: 75%;
  height: auto;
  margin: auto;
}

.title-section h3 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu,
    Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  text-align: center;

  margin-top: 20px;
  margin-bottom: 0px;

  color: #202020;
}

/* ----- Responsive ----- */

@media (max-width: 480px) {
  .highlights-div {
    height: 100%;
    width: 100% !important;
    margin: 0 auto;
    padding: 0;

    flex-direction: column;
  }

  .highlight-element-row {
    width: 100%;
    margin: 20px auto;
  }

  .icon-section {
    width: 80px !important;
    height: 80px !important;
  }

  .icon-section h1 {
    font-size: 32px;
  }

  .title-section h3 {
    font-size: 16px;
  }
}
