.input_field {
  border: 2px solid transparent;
  background-color: #f8feff;
  box-shadow: inset 1px 1px 3px 1px rgba(60, 60, 60, 0.1);
  border-radius: 10px;
  color: #f3b300 !important;
  cursor: pointer;
  transition: background 0.2s ease-in-out;
  font-size: 16px;
  font-family: inherit;
  text-transform: capitalize;
  font-weight: 400;
}
