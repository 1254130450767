.actionCont {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
  height: 100%;
  animation: fadeIn 0.5s ease;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.actionButton {
  margin: 10px;
  padding: 10px 50px;
  border-radius: 5px;
  color: white;
  font-size: 1.2em;
  cursor: pointer;
  transition: 0.3s;
}

.overlayPayment {
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}
