.DocAddPopup-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.DocAddPopup-content form {
  width: 100%;
  height: 50%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.DocAddPopup-content button {
  width: 30%;
  min-height: 40px !important;
  margin: 20px auto;
  border-radius: 10px;

  font-size: 18px;
  font-weight: 500;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu,
    Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
