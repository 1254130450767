.secondMessHalf {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 96%;
  margin: 0px auto;
  gap: 20px;
  margin-bottom: 30px;
}

.messSwapTable {
  width: 70%;
  height: 80vh;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}

.schedularPart {
  width: 30%;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.headPart {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.headPart > p {
  font-size: 22px;
  font-weight: 600;
  color: #313131;
  margin: 8px 0px;
}

.switch {
  width: 140px;
  height: 50px;
  background-color: white;
  border-radius: 6px;
  position: relative;
  cursor: pointer;
  box-shadow: inset 0px 0px 10px 4px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s;
}
.switch.on {
  background-color: #4ade80;
}
.slider {
  width: 55px;
  height: 40px;
  background-color: white;
  border-radius: 6px;
  position: absolute;
  top: 5px;
  left: 5px;
  transition: transform 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}
.switch.on .slider {
  transform: translateX(74px);
}

.checkState {
  width: 60px;
  height: 40px;
  font-size: 16px;
  font-weight: bold;
  color: #90ffb9;
  border-radius: 6px;
  position: absolute;
  top: 5px;
  left: 10px;
  /* transition: transform 0.5s; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.switch.off .checkState {
  transform: translateX(60px);
  color: #989898;
}

.formPart {
  width: calc(100% - 20px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 10px;
  margin: 20px auto;
}

.formPart input {
  height: 24px;
  width: 200px;
  display: block;
  padding: 5px;
  border-radius: 10px;
  background-color: #f8feff;
  border: 1px solid transparent;
  box-shadow: inset 1px 1px 3px 1px rgba(60, 60, 60, 0.1);
  font-size: 16px;
  font-family: inherit;
  /* text-transform: capitalize; */
  color: rgb(225, 183, 0);
  font-weight: 400;
  transition: all 0.5s ease;
}

.studentCnt {
  width: 80px !important;
}

.formPart input:focus {
  border: 2px solid #f3b300;
}

.formPart input::placeholder {
  color: #f3b2006d;
}

.formPart input:active {
  border: 2px solid #f3b300;
}

.items7 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  gap: 10px;
}

.items7 > span {
  font-size: 16px;
  font-weight: 600;
  color: #313131;
}

.buttonPart {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.buttonPart > button {
  color: white;
  cursor: pointer;
  padding: 6px 30px;
  font-size: 18px;
  border-radius: 5px;
}

.approveButton,
.rejectButton {
  padding: 5px 10px;
  border-radius: 3px;
}
