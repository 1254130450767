.generalTableComponent {
  width: 100%;
  height: 100%;
}

.generalTable {
  border-collapse: initial;
  border-radius: 10px;
  display: block;
  overflow-y: auto;
  position: relative;
  width: 100%;
  height: 100%;
}

.courseAssignTablePart {
  max-height: 60vh;
}

.generalTableHead {
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 20;
}

.generalTableTH {
  background: #f3b300 !important;
  text-align: center;
  color: white;
  padding: 8px 20px;
  text-align: center;
  border-bottom: 1px solid #e8e8e8;
  border-right: 1px solid #e8e8e8;
}

.generalTableTD {
  padding: 8px 20px;
  text-align: center;
  border-bottom: 1px solid #e8e8e8;
  border-right: 1px solid #e8e8e8;
}

.loadingState {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.FailedMark {
  background-color: #ff787acd;
}

.PendingMark {
  background-color: #fbd260d0;
}

.SuccessMark {
  background-color: #6cfe6cd0;
}
