@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Akaya+Kanadaka&family=Alatsi&family=Mochiy+Pop+One&display=swap');

@font-face {
  font-family: 'F25_Bank_Printer';
  src: url('./assets/fonts/F25_Bank_Printer.woff');
}

body {
  margin: 0;
  padding: 0;
  width: 100%;
  overflow-x: hidden;
  font-family: 'Roboto', sans-serif;
}

.dashboard {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 2%;
}

.left-div {
  width: 30%;
}

.right-div {
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.container-unknown {
  border: none;
  border-radius: 15px;
  box-shadow: 2px 2px 10px 4px rgba(32, 32, 32, 0.25);
  padding: 2%;
}

.profile-img {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 20vh;
  width: 100%;
}

.profile-img img {
  border: none;
  box-shadow: 2px 2px 10px 4px rgba(32, 32, 32, 0.25);
  border-radius: 50%;
}

.hr-line {
  border: 1px solid black;
  width: 100%;
  height: 0.01px;
}

.detail-1,
.detail-2,
.detail-3 {
  display: flex;
  flex-direction: column;
  padding-inline-start: 2%;
}

img:hover::after {
  content: attr(alt);
  position: absolute;
  background-color: #000;
  color: #fff;
  padding: 5px;
  z-index: 1;
}

.primary {
  align-items: center;
}

input,
select,
option {
  border-radius: 6px !important;
}
