.navbar {
  display: flex;
  width: 100%;
  height: 80px;
  box-shadow: 1px 1px 10px 2px rgba(49, 49, 49, 0.1);
  position: sticky;
  top: 0;
  backdrop-filter: blur(10px);
  background: rgba(255, 255, 255, 0.351);
  z-index: 1000;
}

.container {
  width: 90%;
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.role-type {
  height: 40px;
  width: 120px;
  background-color: #f8feff;
  box-shadow: inset 1px 1px 3px 1px rgba(60, 60, 60, 0.1);
  border-radius: 10px;
  color: #f3b300;
  cursor: pointer;
  transition: background 0.2s ease-in-out;

  font-size: 16px;
  font-family: inherit;
  text-transform: capitalize;
  font-weight: 400;

  display: flex;
  justify-content: space-around;
  align-items: center;
}

.nav-logo {
  width: 60%;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  cursor: pointer;
}

.nav-logo img:nth-child(1) {
  width: 60px;
  margin: 2px;
}
.nav-logo img:nth-child(2) {
  max-height: 42px;
  margin-left: 1%;
}

.nav-right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 30%;
  height: 100%;
}

.nav-help a {
  color: black;
  text-decoration: none;
}

.nav-logout {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 50%;
}
.nav-logout button {
  display: flex;
  justify-content: space-around;
  align-items: center;
  border: none;
  border-radius: 9px;
  background-color: #f3b300;
  padding: 6%;
  color: white;
  font-weight: 500;
  font-size: 20px;
  font-family: roboto;
}

.nav-logout button img {
  width: 25%;
}

.nav-help {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.nav-help a {
  text-decoration: none;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu,
    Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
}

.nav-help button {
  background: transparent;
  border: none;
  font-size: 14px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu,
    Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 500;
  cursor: pointer;
}
.nav-help button:hover {
  color: #5a6dff;
}

/* ----- Responsive ----- */

@media (max-width: 768px) {
  .navbar {
    height: 60px;
  }

  .role-type {
    display: none;
  }

  .nav-logo {
    width: 50%;
    height: 40px;
  }

  .nav-logo img:nth-child(1) {
    width: 40px;
    margin: 2px;
  }
  .nav-logo img:nth-child(2) {
    display: none;
  }

  .nav-right {
    width: 50%;
    height: 40px;
  }

  .nav-help {
    display: none;
  }

  .nav-logout button {
    width: 100%;
    padding: 10%;
    font-size: 16px;
  }
}
