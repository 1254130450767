.tableContainerBank{
  align-items: center;
  border-radius: 20px;
  box-shadow: 1px 1px 10px 2px #3131311a;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  margin: 0 auto 30px;
  padding: 30px;
  width: 90%;
  margin-top: 50px;
}

.bankDetailTable{
    width: 90%;
    margin: auto;
    border-collapse: collapse;
    margin-top: 20px;
    margin-bottom: 20px;
    box-shadow: 1px 1px 10px 2px #3131311a;
    border-radius: 10px;
    height: 600px;
    min-height: fit-content;
    overflow: scroll;
}