.login-div {
  border: 2px solid #fff;
  background: linear-gradient(124deg, #fff 0%, rgba(255, 255, 255, 0.2) 100%);
  backdrop-filter: blur(3px);
  border-radius: 20px;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;

  width: 300px;
}
.login-header {
  width: 100%;
  height: 20%;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  border-bottom: 1px solid #4e4e4e;
  margin-bottom: 10px;
}
.login-header a {
  width: 30%;
  text-decoration: none;
  color: #f3b300;
  font-weight: 400;
  font-size: 18px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu,
    Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-right: 10%;
}
.login-header a i {
  width: 20px;
  height: 20px;
  background-color: #fff;
  padding: 6px;

  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
.login-header a:hover {
  color: #5a6dff;
  font-weight: 500;
}
.login-header h2 {
  width: 70%;
  margin-left: 10%;
  font-size: 22px;
  font-weight: 600;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu,
    Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.login-message {
  width: 100%;
}
.login-message p {
  margin-left: 10%;
  font-size: 18px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu,
    Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 400;
  text-align: left;

  margin-top: 5px;
  margin-bottom: 10px;
}

.login-form {
  width: 100%;
  height: 80%;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.login-form form {
  width: 80%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.login-input {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: left;
}
.login-input h3 {
  font-size: 18px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu,
    Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 500;

  margin: 0;
  margin-bottom: 5px;
}

.login-form input {
  width: 90%;
  height: 24px;
  border: 2px solid transparent;
  background: #f8feff;
  box-shadow: inset 1px 1px 3px 1px rgba(60, 60, 60, 0.1);
  padding: 5px 10px;
  margin-bottom: 10px;
  border-radius: 10px;
  color: #f3b300;
  cursor: pointer;
  transition: background 0.2s ease-in-out;

  font-size: 16px;
  font-family: inherit;
  /* text-transform: capitalize; */
  font-weight: 400;
  /* text-transform: lowercase; */
}

.login-form input:focus {
  outline: none;
  border: 2px solid #f3b300;
}

.login-button {
  width: 100%;
  height: 20%;

  display: flex;
  flex-direction: column;
  align-items: center;

  margin: 10px 0;
  margin-bottom: 20px;
}
.other-btns {
  width: 80%;
  flex-direction: row;
  justify-content: space-evenly;
}

.login-button button {
  width: 80%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 10px;
  background-color: #f3b300;
  color: white;
  font-weight: 500;
  font-size: 20px;
  font-family: sans-serif;
}

.login-button,
a {
  text-decoration: none;
  color: #4e4e4e;
  font-weight: 400;
  font-size: 18px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu,
    Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

  margin-top: 10px;
}
.login-button a span {
  color: #5a6dff;
  text-decoration: underline;
  font-weight: 500;
}

.login-button a:hover {
}

.login-button button:hover {
  border: 2px solid #5a6dff;
  background-color: #5a6dff;

  cursor: pointer;
  transition: 0.5s ease-in-out;
}

/* ----- Responsive ----- */

@media (max-width: 480px) {
  .login-div {
    width: 90%;
  }
}
