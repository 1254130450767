.top-div {
  width: 100%;
  margin-top: 60px;
  margin-bottom: 50px;

  display: flex;
}
.mid-div,
.bottom-div {
  width: 100%;
  margin-bottom: 50px;

  display: flex;
}

.flex-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.past-due {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 20px auto;
}

.past-due h2 {
  font-size: 24px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 500;
  margin: 0;
}

.past-due h2 span {
  font-size: 24px;
  color: #5a6dff;
}

.past-due button {
  width: 150px;
  height: 40px;
  border: none;
  border-radius: 10px;
  background-color: #f3b300;
  color: white;
  font-weight: 500;
  font-size: 18px;
  font-family: sans-serif;
}

@media (max-width: 480px) {
  .past-due {
    flex-direction: column;
  }
  .past-due h2 {
    margin: 10px;
  }
}
