.headNote{
  background: #f3b300;
    margin: 0px;
    text-align: center;
    color: white;
    padding: 7px;
    font-size: 16px;
    font-weight: 500;
}

.titleCont{
  width: 92% !important;
}

.feedbackHeading{
  font-size: 36px !important;
  margin-bottom: 5px;
}

.subNote{
  margin: 5px;
  color: #606060;
}

.subNote>span{
  font-weight: 600;
  color: #f30000;
}

.courseContainer2{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 24px;
  margin-bottom: 50px;
}

.courseCard{
  width: 90%;
  margin: auto;
  box-shadow: 1px 1px 10px 2px rgba(49, 49, 49, 0.1);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.courseCardTop{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.courseCardTitle{
  font-size: 24px;
  font-weight: 600;
}

.courseCardBottom{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 18px;
  margin-top: 20px;
}

.facultyCard{
  width: 96%;
  margin: auto;
  box-shadow: inset 1px 1px 10px 2px rgba(49, 49, 49, 0.1);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  padding: 15px;
}

.facultyCardTop{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.facultyCardTitle{
  font-size: 20px;
  font-weight: 600;
}

.questionContainer{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
}

.ratingTable{
  width: 100%;
  border-collapse: separate;
  border-radius: 10px;
  overflow-y: auto;
  display: block;
  position: relative;
}

.ratingTable tr{
  background-color: #effbff;
  transition: all 0.2s ease-in-out;
}

.ratingTable tr:nth-child(odd){
  background-color: #fbf9ed;
}

.ratingTable tr:hover{
  box-shadow: inset 0px 0px 10px 2px rgba(49, 49, 49, 0.130);
}

.ratingTable td{
  border-bottom: 1px solid #e8e8e8;
  border-right: 1px solid #e8e8e8;
  padding: 8px 20px;
  text-align: start;
  margin: 0px;
  font-size: large;
}

.questionPart{
  width: 50% ;
}

.ratingPart{
  width: 10%;
  text-align: center !important;
}

.bottomCont{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 40px;
}

.finalSub{
  color: white;
  font-weight: 500;
  font-size: 20px;
  font-family: roboto;
  padding: 8px 40px;
  border-radius: 8px;
}

.disabledBtn{
  opacity: 0.5;
  cursor: not-allowed;
}

.done{
  display: none;
}

.warningNote{
  color: #606060;
}

.remove{
  display: none;
}

.loadingContainer2{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
  backdrop-filter: blur(5px);
}

.reveiwSubmitted{
  background-color: #f0fcff;
}