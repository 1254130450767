.bulk-generation-div {
  width: 90%;
  margin: auto;
  box-shadow: 1px 1px 10px 2px rgba(49, 49, 49, 0.1);
  border-radius: 20px;
}

.bulk-generation-div h2 span {
  font-weight: 400;
}

.bulk-wali {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 20px;
}

.bulk-wali input {
  width: 100%;
  height: 36px;
  border: none;
  border-radius: 10px;
  background-color: #f8feff;
  border: 1px solid transparent;
  box-shadow: inset 1px 1px 3px 1px rgba(60, 60, 60, 0.1);
  font-size: 16px;
  font-weight: 500;
  font-family: sans-serif;
  padding: 0 20px;

  margin-bottom: 20px;
}

.bulk-gen-btn {
  width: 20%;
  height: 36px;
  border: none;
  border-radius: 10px;
  background-color: #f3b300;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  cursor: pointer;
}

.loading-box {
  position: relative;
  width: 90%;
  margin: auto;
  box-shadow: 1px 1px 10px 2px rgba(49, 49, 49, 0.1);
  border-radius: 20px;

  /* width: 400px; */
  height: 50px;
  border: 2px solid #ededed;
  overflow: hidden;
}

.loader {
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 50px;
  background: #f3b300;
  left: 0%;
}

.loader {
  left: -100%;
  animation: load 5s linear infinite;
}

@keyframes load {
  0% {
    left: -100%;
  }
  100% {
    left: 100%;
  }
}
