

.top-div {
  width: 100%;
  margin-top: 50px;
  margin-bottom: 60px;

  display: flex;
}

.profile-section {
  width: 90%;
  height: 100%;
  margin: auto;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.display-div {
  width: calc(70% - 10px);
  height: auto;
}
.side-div {
  width: calc(30% - 10px);
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.reportsView-section {
  width: 90%;
  height: 100%;
  margin: auto;

  border-radius: 20px;
  box-shadow: 1px 1px 10px 2px rgba(49, 49, 49, 0.1);

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.report {
  width: calc(50% - 10px);
  height: 100%;
  margin: 1%;

  border-radius: 20px;
  background-image: url("../../assets/watermark.svg");
  background-repeat: no-repeat;
  background-position: center;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.display-item,
.search-AV,
.contact-div,
.printing-div {
  width: 100%;
  box-shadow: 1px 1px 10px 2px rgba(49, 49, 49, 0.1);
  border-radius: 20px;
  align-items: center;
}

.display-item {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.search-AV,
.contact-div,
.printing-div {
  margin-left: 5%;
}

/* .report-header {
  width: 100%;
  height: 30vh;
  margin: auto;
  background-image: url("../../assets/heading-banner.jpg");
  background-size: fill;

} */

.report-header,
.report-header img {
  width: 100%;
  height: auto;
  border-radius: 30px 30px 0 0;
}
.report-title {
  width: 80%;
  height: 6vh;
  margin: 5px auto;

  display: flex;
  justify-content: space-around;
  align-items: center;

  border-top: 2px solid #4e4e4e;
  border-bottom: 2px solid #4e4e4e;
}
.report-title h3 {
  font-size: 20px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 500;
}

.report-profile-details {
  width: 94%;
  height: 100%;
  margin: auto;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  margin: 20px auto;
}
.ProfileSummary {
  width: calc(75% - 10px);
  height: 100%;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.profile-summary-item {
  width: calc(50% - 5px);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.left-text {
  text-align: left;
}
.right-text {
  text-align: right;
}
.ProfileSummary h4,
.verification-indicator h4,
.space-for-sign h4 {
  font-size: 18px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;

  font-weight: 500;
  margin: 10px 0;
}
.ProfileSummary h4 span,
.verification-indicator h4 span,
.space-for-sign h4 span {
  font-weight: 400;
  font-style: italic;
}

.profile-picture {
  width: 25%;
  height: 100%;
  margin: auto;

  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.profile-picture img {
  width: 90%;
  height: auto;
  max-height: 210px;
  aspect-ratio: 5/6;
  border-radius: 8px;
}

.course-table-section,
.fee-table-section {
  width: 94%;
  height: 100%;
  align-items: center;

  margin-bottom: 20px;
}

.verification-indicator,
.space-for-sign {
  width: 94%;
  margin-bottom: 20px;
}

.indicator-div,
.sign-div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.sign-item {
  width: 100%;
  height: 100%;

  text-align: center;

  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.left-sign-text {
  text-align: left;
}
.right-sign-text {
  text-align: right;
}

.space-unit {
  width: 100%;
  height: 8vh;
}

.reg-status-right {
  margin-right: 120px;
}

.search-AV {
  width: 95%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.search-title-AV {
  width: 90%;
  border-bottom: 2px solid #4e4e4e;
  margin: 10px auto;
}

.search-items-AV {
  width: 100%;
  height: 100%;

  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.search-item-AV {
  width: 90%;
  height: 10vh;

  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.search-title-AV h4,
.search-item-AV h4 {
  margin: 10px 0;

  font-size: 20px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 500;
}

.search-item-AV h4 {
  font-size: 18px;
  font-weight: 400;
}

.search-item-AV input {
  width: 60%;
}

.contact-div {
  width: 95%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  margin-top: 50px;
}

.contact-div h4,
.printing-div h4 {
  margin: 10px 0;

  font-size: 20px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 500;
}
.contact-details h4 {
  font-size: 18px;
}
.contact-div h4 span {
  font-weight: 400;
  font-style: italic;
}

.contact-div-title {
  width: 90%;
  border-bottom: 2px solid #4e4e4e;
  margin: 10px auto;
}

.contact-details {
  width: 90%;
  height: auto;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: left;
  margin: 10px auto;
}

.printing-div {
  width: 95%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  margin-top: 25px;
}

.printing-div-title {
  width: 90%;
  border-bottom: 2px solid #4e4e4e;
  margin: 10px auto;
}
.printing-div-title h4 {
  margin: 10px 0;
}

.printing-div-item {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;

  margin: 20px auto;
}

.printing-div-item button {
  width: 80%;
  height: 6vh;
  border-radius: 10px;
  border: none;
  outline: none;
  background-color: #f3b300;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
}

.printing-div-item button:hover {
  background-color: #5a6dff;

  cursor: pointer;
  transition-duration: 1s;
  transition: ease-in-out;
}

.OverallSummary {
  width: 90%;
  height: 100%;
  margin: auto;

  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;

  box-shadow: 1px 1px 10px 2px rgba(49, 49, 49, 0.1);
  border-radius: 20px;
}

.overall-summary-title h4 {
  font-size: 20px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 500;
}
