.LoginPage {
  width: 100%;
  height: 100vh;
  background-color: #fcffff;
  overflow: hidden;
  position: relative;
}

.login-section {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.exist-account {
  width: 60%;
  height: calc(60% - 10px);

  background-image: url("/public/assets/login-background.jpg");
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;

  box-shadow: 1px 1px 10px 2px rgba(49, 49, 49, 0.1);
  border-radius: 20px;

  display: flex;
  justify-content: right;
  align-items: center;
}

.login-box {
  position: absolute;
  left: 55%;
}

.new-admission {
  width: 60%;
  height: calc(20% - 10px);

  box-shadow: 1px 1px 10px 2px rgba(49, 49, 49, 0.1);
  border-radius: 20px;

  display: flex;
  justify-content: center;
}

.new-admission-title {
  width: 50%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  border-right: 1px solid black;
  background-color: #fff;
  border-radius: 20px 0 0 20px;
}

.new-admission-title h2 {
  margin-bottom: 0;
  font-size: 24px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 600;

  background: linear-gradient(90deg, #1461ff 50.2%, #1b2767 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.new-admission-title p {
  font-size: 18px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 400;

  margin-top: 10px;
}

.new-admission-button {
  width: 50%;
  height: 100%;
  background-color: #f8feff;
  border-radius: 0 20px 20px 0;

  display: flex;
  justify-content: center;
  align-items: center;
}

.new-admission-button button {
  width: calc(80% - 10px);
  height: 50px;
  border: none;
  border-radius: 10px;
  background-color: #f3b300;
  color: white;
  font-weight: 500;
  font-size: 18px;
  font-family: sans-serif;

  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.new-admission-button button:hover {
  background-color: #5a6dff;

  cursor: pointer;
  transition-duration: 1s;
  transition: ease-in-out;
}

.left-footer-dock,
.right-footer-dock {
  width: 250px;
  height: 300px;

  background-color: #f8feff;
  box-shadow: 1px 1px 10px 2px rgba(49, 49, 49, 0.1);

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.left-footer-dock {
  position: fixed;
  top: 50%;
  left: -200px;
  transform: translate(0, -50%);
  border-radius: 0 20px 20px 0;
}
.right-footer-dock {
  position: fixed;
  top: 50%;
  right: -200px;
  transform: translate(0, -50%);
  border-radius: 20px 0 0 20px;
}

.left-footer-dock:hover {
  left: 0;
  transition-duration: 5s;
  transition: ease-in-out;
}
.right-footer-dock:hover {
  right: 0;
  transition-duration: 5s;
  transition: ease-in-out;
}

.dock-title {
  width: calc(20% - 0px);
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
}

.dock-contents {
  width: calc(80% - 0px);
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.dock-content {
  width: calc(100% - 10px);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-end;

  border-right: 1px solid black;
}

.right-dock-content {
  align-items: flex-start;
  border-right: transparent;
  border-left: 1px solid black;
}

.dock-content h4 {
  margin: 0;
  font-size: 16px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 500;

  text-align: right;
  margin: 10px 5px 5px 5px;
}

.dock-content p {
  margin: 0;
  font-size: 14px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 400;

  text-align: left;
  margin: 5px;
}

.dock-content p span {
  font-weight: 500;
}

.para-click {
  cursor: pointer;
}
.para-click:hover {
  color: #5a6dff;
}

/* ----- Responsive ----- */

@media (max-width: 480px) {
  .LoginPage {
    height: 100vh;
    width: 100vw;
    background-image: url("/public/assets/login-background.jpg");
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
  }
  .login-section {
    justify-content: space-between;
  }
  .exist-account {
    width: 100%;
    height: calc(75% - 10px);
    margin: 0;

    border-radius: 0;
    background-image: none;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .login-box {
    width: 100%;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);

    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10%;
  }

  .new-admission {
    width: 90%;
    height: 25%;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    border: 2px solid #fff;
    background: linear-gradient(124deg, #fff 0%, rgba(255, 255, 255, 0.2) 100%);
    backdrop-filter: blur(3px);
    border-radius: 20px 20px 20px 20px;

    margin-bottom: 10%;
  }
  .new-admission-title {
    width: 100%;
    height: 50%;
    border-radius: 20px 20px 0 0;
    border-right: none;
    background-color: transparent;
  }
  .new-admission-button {
    width: 100%;
    height: 50%;
    border-radius: 0px;
    background-color: transparent;
  }

  .left-footer-dock,
  .right-footer-dock {
    background: none;
    box-shadow: none;
    z-index: 99;
    top: 60%;
  }

  .dock-title {
    height: 25%;
    background-color: #f8feff;
    z-index: 99;
  }
  .dock-contents {
    background-color: #f8feff;
    box-shadow: 1px 1px 10px 2px rgba(49, 49, 49, 0.1);
  }

  .dock-content {
    border-right: none;
  }
  .right-dock-content {
    border-left: none;
  }

  .left-footer-dock .dock-title,
  .left-footer-dock .dock-contents {
    border-radius: 0 20px 20px 0;
  }
  .right-footer-dock .dock-title,
  .right-footer-dock .dock-contents {
    border-radius: 20px 0 0 20px;
  }
}
