.PersonalDetailsPopUp {
  width: 60%;
  height: auto;
  max-height: 80vh;
  overflow: auto;
  margin: 0 auto;

  position: fixed;
  top: 53%;
  left: 50%;
  transform: translate(-50%, -50%);

  background-color: #fff;
  box-shadow: 5px 5px 100px 100px rgba(49, 49, 49, 0.2);
  border-radius: 20px;
  padding: 10px;
  z-index: 99;
}

.documents-popup-title {
  width: 96%;
  height: 60px;
  margin: 0 auto;

  border-bottom: 2px solid #4e4e4e;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 30px;
}

.documents-popup-title h2 {
  margin: 0;
  font-size: 24px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu,
    Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

  color: #4e4e4e;
}

.documents-popup-title h2 span {
  font-weight: 500;
}

.documents-popup-title button {
  width: 32px;
  height: 32px;

  border: none;
  border-radius: 10px;
  background-color: #4e4e4e;
  color: #fff;

  font-size: 18px;
  font-weight: 500;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu,
    Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  text-align: center;

  cursor: pointer;
}

.documents-popup-title button:hover {
  background-color: #fa513e;
}

.documents-popup-content {
  width: 96%;
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.view-mode {
  justify-content: space-around;
}

.view-mode h4 {
  margin: 0;
  font-size: 18px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu,
    Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 500;
}

.approve-reject {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 20px;
  align-items: center;
  margin: 5px 0px;
  margin-bottom: 12px;
}

.approve-reject button {
  padding: 5px 10px;

  border: none;
  border-radius: 5px;

  font-size: 16px;
  font-weight: 500;
  text-align: center;

  cursor: pointer;
}

.reject {
  border: 2px solid #fa603e !important;
}

.reject-form {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  height: auto;
  max-height: 80vh;
  overflow: auto;
  background: #fff;
  box-shadow: 5px 5px 10px 10px rgba(49, 49, 49, 0.2);
  border-radius: 10px;
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 2px solid #bebebe;
}

.reject-form-content {
  width: 100%;
}

.reject-form-content > textarea {
  width: 90%;
  height: 100px;
}

.reject-form-content > button {
  padding: 8px 20px;
  margin: 10px 0px;
  border: none;
  border-radius: 5px;
}
