.CourseRegistrationSection {
  width: 90%;
  height: 100%;
  margin: 0 auto;

  border-radius: 20px;
  box-shadow: 1px 1px 10px 2px rgba(49, 49, 49, 0.1);
}

.course-registration-section {
  width: 96%;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.course-registration-section-title {
  width: 100%;
}

.course-registration-section-title h3 {
  margin-left: 0px;
  font-size: 20px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu,
    Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 600;
}

.Select-semester {
  width: 100%;
  height: 100%;
  margin-bottom: 20px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.Select-semester label {
  font-size: 20px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu,
    Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 400;
}

.Select-semester-label,
.Select-semester-input {
  width: 50%;
  height: 100%;
  display: flex;
}

.Select-semester select {
  height: 36px;
  width: calc(100%);
  display: block;
  padding: 5px;
  border-radius: 10px;
  background-color: #f8feff;
  border: 1px solid transparent;
  box-shadow: inset 1px 1px 3px 1px rgba(60, 60, 60, 0.1);
  font-size: 16px;
  font-family: inherit;
  text-transform: capitalize;
  color: rgb(225, 183, 0);
  font-weight: 400;
  transition: all 0.5s ease;
}
.course-structure {
  margin-bottom: 20px;
}
.choose-electives {
  width: 100%;
  height: 100%;
  margin-bottom: 20px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.choose-electives-title {
  width: 100%;
  height: 100%;
  margin-bottom: 10px;

  display: flex;
  flex-direction: row;
  align-items: center;
}

.choose-electives-title h3 {
  margin-left: 0px;
  font-size: 20px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu,
    Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 500;
}

.choose-electives-title h3 span {
  font-size: 18px;
  font-weight: 400;
}

.choose-electives-title a {
  text-decoration: none;
  font-weight: 400;
  color: #007bff;
}

.choose-electives-title a:hover {
  text-decoration: underline;
  font-weight: 500;
}

.choose-preferences {
  width: 100%;
  height: 100%;
  margin-bottom: 20px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.preference {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.preference-label,
.preference-input {
  width: 40%;
  height: 100%;

  margin-right: 5%;

  display: flex;
  flex-direction: row;
  align-items: center;
}

.preference-label label {
  font-size: 20px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu,
    Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 400;
}

.preference-input select {
  width: 100%;
  height: 100%;
  display: block;
  padding: 5px;
  border-radius: 10px;
  background-color: #f8feff;
  border: 1px solid transparent;
  box-shadow: inset 1px 1px 3px 1px rgba(60, 60, 60, 0.1);
  font-size: 16px;
  font-family: inherit;
  text-transform: capitalize;
  color: rgb(225, 183, 0);
  font-weight: 400;
  transition: all 0.5s ease;
}

.apply-minor {
  width: 100%;
  height: 100%;
  margin-bottom: 20px;

  display: flex;
  flex-direction: row;
  align-items: center;
}

.apply-minor-title {
  width: calc(25%);
  height: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
}

.apply-minor-inputs {
  width: calc(75%);
  height: 100%;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.apply-minor-title h3 {
  margin-left: 0px;
  font-size: 20px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu,
    Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 500;
}
.apply-minor-title h3 span {
  font-weight: 400;
}

.apply-minor-inputs form {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.apply-minor-inputs input {
  width: 100%;
  height: 100%;
  display: block;
  padding: 5px;
  border-radius: 10px;
  background-color: #f8feff;
  border: 1px solid transparent;
  box-shadow: inset 1px 1px 3px 1px rgba(60, 60, 60, 0.1);
  font-size: 16px;
  font-family: inherit;
  text-transform: capitalize;
  color: rgb(225, 183, 0);
  font-weight: 400;
  transition: all 0.5s ease;
  text-align: center;
}

.enter-course-details {
  width: calc(30% - 10px);
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.enter-course-details input {
  margin: 0 5px;
}

.enter-course-details button {
  width: 100%;
  height: 36px;
  border-radius: 10px;
  border: none;
  background-color: #4e4e4e;
  color: #f8feff;
  font-size: 16px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu,
    Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 500;
  cursor: pointer;
}

.submit-registration {
  width: 100%;
  height: 100%;

  margin-bottom: 20px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.submit-registration p {
  width: 60%;
  height: 100%;
  font-size: 16px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu,
    Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 300;
}

.submit-registration button {
  width: 320px;
  height: 50px;
  border-radius: 10px;
  border: none;
  background-color: #f3b300;
  color: #f8feff;
  font-size: 20px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu,
    Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.5s ease;
}

.submit-registration button:hover {
  background-color: #5a6dff;
}
