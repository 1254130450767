.student-page {
  margin: 60px 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sp-container {
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

/* ----- Responsive ----- */

@media (max-width: 480px) {
  .sp-container {
    flex-direction: column;
  }
  .student-dashboard-div {
    width: 100% !important;
    height: 100% !important;
  }
}
